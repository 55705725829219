import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span title=\"Session Change, settled price: {{format-number instrument.settlementPrice instrument.product.formatting}}\"\n  class=\"{{style}}\">\n  <VaultPrice @instrument={{instrument}} @price={{instrument.sessionPriceChange}} style=\"display: inline;\" />\n  ({{format-number instrument.sessionPriceChangePercentage \"0,0.00%\"}})\n  {{#if arrow}}\n  <FaIcon @icon={{arrow}} />\n  {{/if}}\n\n</span>", {"contents":"<span title=\"Session Change, settled price: {{format-number instrument.settlementPrice instrument.product.formatting}}\"\n  class=\"{{style}}\">\n  <VaultPrice @instrument={{instrument}} @price={{instrument.sessionPriceChange}} style=\"display: inline;\" />\n  ({{format-number instrument.sessionPriceChangePercentage \"0,0.00%\"}})\n  {{#if arrow}}\n  <FaIcon @icon={{arrow}} />\n  {{/if}}\n\n</span>","moduleName":"agropur-client/components/vd-session-change.hbs","parseOptions":{"srcName":"agropur-client/components/vd-session-change.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

  classNames: ['vd-session-change'],

  format: computed('instrument.product.rdFormat', function() {
    const result = '(0,0.00)';
    if (this.get('instrument.product.rdFormat')) {
      return this.get('instrument.product.rdFormat').replace('$', '');
    }
    return result;
  }),

  style: computed('instrument.sessionPriceChange', function() {
    let color = '';
    if (this.get('instrument.sessionPriceChange') < 0) {
      color = 'text-danger';
    }
    if (this.get('instrument.sessionPriceChange') > 0) {
      color = 'text-success';
    }
    return color;
  }),

  arrow: computed('instrument.sessionPriceChange', function() {
    let arrow = '';
    if (this.get('instrument.sessionPriceChange') < 0) {
      arrow = 'caret-down';
    }
    if (this.get('instrument.sessionPriceChange') > 0) {
      arrow = 'caret-up';
    }
    return arrow;
  })
});
