import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import EmberObject from '@ember/object';

export default Route.extend(AuthenticatedRouteMixin, {

  model() {
    const { basisProduct, endProductBasisProduct, spotProduct, basisInstruments, endProductBasisInstruments, spotInstruments, allBasisInstruments, basisMarketData, organization, isOrgAdmin } = this.modelFor('authenticated');

    if (!organization.showAgropurBasis || !isOrgAdmin) {
      this.transitionTo('authenticated.dashboard');
    }

    return hash({
      basisProduct,
      endProductBasisProduct,
						spotProduct,
      basisInstruments,
      endProductBasisInstruments,
						spotInstruments,
      allBasisInstruments,
      basisMarketData,
      isOrgAdmin,
    });
  },
  afterModel(model) {

    model.selectableInstruments = model.basisInstruments.map((ins) => {
      return EmberObject.create({
        instrument: ins,
        checked: false
      });
    });

    model.selectableEndProductInstruments = model.endProductBasisInstruments.map((ins) => {
      return EmberObject.create({
        instrument: ins,
        checked: false
      });
    });

				model.selectableSpotInstruments = model.spotInstruments.map((ins) => {
					return EmberObject.create({
							instrument: ins,
							checked: false
					});
			});

  }


});
