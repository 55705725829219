import { computed } from "@ember/object";
import Controller from "@ember/controller";
import DateUtils from "agropur-client/utils/date-utils";
const { dateInput, toUtc, format } = DateUtils;
import ENV from "agropur-client/config/environment";

export default Controller.extend({
	ENV,
	updatedBasisPrice: null,
	updateEndProductBasisPrice: null,
	updateSpotPrice: null,

	monthsArray: computed("model.allBasisInstruments", function () {
		let months = {};

		this.get("model.allBasisInstruments").forEach((instrument) => {
			if (!months[instrument.date]) {
				months[instrument.date] = {};
			}
			months[instrument.date][instrument.product.content.name] = instrument;
		});

		return Object.entries(months)
			.map((m) => {
				return {
					date: m[0],
					basis: m[1]["Agropur Basis"],
					endProduct: m[1]["Uncontracted Basis"],
					spot: m[1]["New Basis"],
				};
			})
			.sortBy("date");
	}),

	checkedInstruments: computed.filterBy(
		"model.selectableInstruments",
		"checked",
		true
	),

	checkedEndProductInstruments: computed.filterBy(
		"model.selectableEndProductInstruments",
		"checked",
		true
	),

	checkedSpotInstruments: computed.filterBy(
		"model.selectableSpotInstruments",
		"checked",
		true
	),

	partialInstrumentsSelected: computed(
		"checkedInstruments.@each.checked",
		function () {
			return (
				this.checkedInstruments.length > 0 &&
				this.checkedInstruments.length < this.model.selectableInstruments.length
			);
		}
	),

	partialEndProductInstrumentsSelected: computed(
		"checkedEndProductInstruments.@each.checked",
		function () {
			return (
				this.checkedEndProductInstruments.length > 0 &&
				this.checkedEndProductInstruments.length <
					this.model.selectableEndProductInstruments.length
			);
		}
	),

	partialSpotInstrumentsSelected: computed(
		"checkedSpotInstruments.@each.checked",
		function () {
			return (
				this.checkedSpotInstruments.length > 0 &&
				this.checkedSpotInstruments.length <
					this.model.selectableSpotInstruments.length
			);
		}
	),

	allInstrumentsSelected: computed(
		"checkedInstruments.@each.checked",
		function () {
			return (
				this.checkedInstruments.length == this.model.selectableInstruments.length
			);
		}
	),

	allEndProductInstrumentsSelected: computed(
		"checkedEndProductInstruments.@each.checked",
		function () {
			return (
				this.checkedEndProductInstruments.length ==
				this.model.selectableEndProductInstruments.length
			);
		}
	),

	allSpotInstrumentsSelected: computed(
		"checkedSpotInstruments.@each.checked",
		function () {
			return (
				this.checkedSpotInstruments.length ==
				this.model.selectableSpotInstruments.length
			);
		}
	),

	actions: {
		saveBasisRecords() {
			const list = this.get("checkedInstruments");
			const price = this.get("updatedBasisPrice");

			list.forEach((el) => {
				const instrument = el.get("instrument");
				const instrumentSymbol = el.get("instrument.symbol");
				const timestamp = format(toUtc(new Date()), "YYYY-MM-DD HH:mm:ss");
				const closeDate = dateInput(toUtc(el.get("instrument.expiration")));

				const model = this.get("store").createRecord("vault-market-datum", {
					instrument,
					instrumentSymbol,
					timestamp,
					closeDate,
					price,
					side: "Last",
					final: false,
				});
				model.save();
			});
			if (this.get("updatedBasisPrice")) {
				this.toggleProperty("showBasisOfferModal");
				this.set("updatedBasisPrice", null);
			} else {
				console.log("no update!");
			}
		},
		saveEndProductRecords() {
			const list = this.get("checkedEndProductInstruments");
			const price = this.get("updateEndProductBasisPrice");

			list.forEach((el) => {
				const instrument = el.get("instrument");
				const instrumentSymbol = el.get("instrument.symbol");
				const timestamp = format(toUtc(new Date()), "YYYY-MM-DD HH:mm:ss");
				const closeDate = dateInput(toUtc(el.get("instrument.expiration")));

				const model = this.get("store").createRecord("vault-market-datum", {
					instrument,
					instrumentSymbol,
					timestamp,
					closeDate,
					price,
					side: "Last",
					final: false,
				});
				model.save();
			});
			if (this.get("updateEndProductBasisPrice")) {
				this.toggleProperty("showEndProductBasisModal");
				this.set("updateEndProductBasisPrice", null);
			} else {
				console.log("no update!");
			}
		},
		saveSpotRecords() {
			const list = this.get("checkedSpotInstruments");
			const price = this.get("updateSpotPrice");

			list.forEach((el) => {
				const instrument = el.get("instrument");
				const instrumentSymbol = el.get("instrument.symbol");
				const timestamp = format(toUtc(new Date()), "YYYY-MM-DD HH:mm:ss");
				const closeDate = dateInput(toUtc(el.get("instrument.expiration")));

				const model = this.get("store").createRecord("vault-market-datum", {
					instrument,
					instrumentSymbol,
					timestamp,
					closeDate,
					price,
					side: "Last",
					final: false,
				});
				model.save();
			});
			if (this.get("updateSpotPrice")) {
				this.toggleProperty("showSpotModal");
				this.set("updateSpotPrice", null);
			} else {
				console.log("no update!");
			}
		},

		toggleBasisOfferModal() {
			this.set("updatedBasisPrice", null);
			this.toggleProperty("showBasisOfferModal");
		},

		cancelBasisOfferModal() {
			this.toggleProperty("showBasisOfferModal");
			this.set("updatedBasisPrice", null);
		},

		toggleEndProductBasisModal() {
			this.set("updateEndProductBasisPrice", null);
			this.toggleProperty("showEndProductBasisModal");
		},

		cancelEndProductBasisModal() {
			this.toggleProperty("showEndProductBasisModal");
			this.set("updateEndProductBasisPrice", null);
		},

		toggleSpotModal() {
			this.set("updateSpotPrice", null);
			this.toggleProperty("showSpotModal");
		},

		cancelSpotModal() {
			this.toggleProperty("showSpotModal");
			this.set("updateSpotPrice", null);
		},

		updateAllSelectedInstruments() {
			if (this.checkedInstruments.length > 0 && !this.partialInstrumentsSelected) {
				this.checkedInstruments.forEach((m) => {
					m.set("checked", false);
				});
			} else {
				this.model.selectableInstruments.forEach((m) => {
					m.set("checked", true);
				});
			}
		},
		updateAllSelectedEndProductInstruments() {
			if (
				this.checkedEndProductInstruments.length > 0 &&
				!this.partialEndProductInstrumentsSelected
			) {
				this.checkedEndProductInstruments.forEach((m) => {
					m.set("checked", false);
				});
			} else {
				this.model.selectableEndProductInstruments.forEach((m) => {
					m.set("checked", true);
				});
			}
		},
		updateAllSelectedSpotInstruments() {
			if (
				this.checkedSpotInstruments.length > 0 &&
				!this.partialSpotInstrumentsSelected
			) {
				this.checkedSpotInstruments.forEach((m) => {
					m.set("checked", false);
				});
			} else {
				this.model.selectableSpotInstruments.forEach((m) => {
					m.set("checked", true);
				});
			}
		},
	},
});
