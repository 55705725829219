import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';
import EmberObject from '@ember/object';

export default Route.extend(AuthenticatedRouteMixin, {
  model() {

    const { periodDate, dairy, displayMode, basisInstruments, spotInstruments, currentUserId, isOrgAdmin } = this.modelFor('authenticated');

    return hash({
      periodDate,
      displayMode,
      classThreeFutures: this.store.peekAll('modern-instrument').filter((i) => {
        return i.name.includes("Class III") && i.productType == 'Future';
      }).sortBy('displayExpiresAt'),
      basisInstruments,
						spotInstruments,
      currentUserId,
      isOrgAdmin,
      manualPositions: this.get('store').query('manual-position', { dairy_id: dairy.id}).then(results => results.filter((position) => {
							return position.get('product_id') === 'NEW_BASIS' || position.get('product_id') === 'BASIS';
				})),
      months: this.get('store').query('month', { start_date: periodDate.format("YYYY-MM-DD"), end_date: moment(periodDate).add(23, 'months').format("YYYY-MM-DD"), dairy_id: dairy.id })
    });
  },
  afterModel(model) {
    model.months = model.months.sortBy("date");

    let month;
    let classThreeinstrument;
    let basisInstrument;
				let spotInstrument;

    for (let i = 0; i < model.months.length; i++) {
      month = model.months[i];
      classThreeinstrument = model.classThreeFutures.find((e) => {
        return moment(e.get('displayExpiresAt')).isSame(month.date, 'day');
      });
      month.set('classThreeMilkInstrument', classThreeinstrument);

      basisInstrument = model.basisInstruments.find((e) => {
        return moment.utc(e.get('expiration')).isSame(month.date, 'month');
      });
      month.set('agropurBasisInstrument', basisInstrument);

						spotInstrument = model.spotInstruments.find((e) => {
							return moment.utc(e.get('expiration')).isSame(month.date, 'month');
					});
					month.set('spotBasisInstrument', spotInstrument);

    }

    model.selectableMonths = model.months.map((m) => {
      return EmberObject.create({
        month: m,
        checked: false
      });
    });

  },
});

