// This function turns a vela price into the barchart equivalent
// for a specific root symbol.
export default function velaToBarchartPrice(symbol, price) {

    let newPrice = price;

    switch (symbol) {
        case 'GDK': newPrice = price / 100;
            break;
        case 'DC': newPrice = price / 100;
            break;
        case 'ZM': newPrice = price / 10;
            break;
        case 'GNF': newPrice = price / 1000;
            break;
        case 'DY': newPrice = price / 1000;
            break;
        case 'CSC': newPrice = price / 1000;
            break;
        case 'CB': newPrice = price / 1000;
            break;
    }

    return newPrice;
}
