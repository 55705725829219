import Controller from '@ember/controller';
import ENV from "agropur-client/config/environment";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class PasswordResetController extends Controller {
	brandLogoUrl;
	brandLogoText;
	programName;
	@tracked emailAddress;
	@tracked submitted = false;

	constructor() {
		super(...arguments);
		this.brandLogoText = ENV.BRAND.logoAltText;
		this.brandLogoUrl = ENV.BRAND.logoPath;
		this.programName = ENV.BRAND.programName;
	}

	@action
	async submitPasswordResetRequest(){

		const redirectURI = `${window.location.origin}/callback`;

		const body = {
			"email": this.emailAddress,
			"redirect_uri": redirectURI
		}

		const url = `${ENV.APP.HOST}/oauth/reset_password`;

		await fetch(url, {
			method: 'POST',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		});

		this.submitted = true;
	}

}