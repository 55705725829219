import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"agropur-client/components/intl-number-format.hbs","parseOptions":{"srcName":"agropur-client/components/intl-number-format.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	// anything which *must* be merged to prototype here
	tagName: 'span',
	locales: "",
	style: null,
	localeMatcher: null,
	currency: null,
	currencyDisplay: null,
	useGrouping: null,
	minimumIntegerDigits: null,
	minimumFractionDigits: null,
	maximumFractionDigits: null,
	minimumSignificantDigits: null,
	maximumSignificantDigits: null,
	value: 0,

	
	formattedValue: computed(	'locales', 
								'style', 
								'localeMatcher', 
								'currency', 
								'currencyDisplay', 
								'useGrouping', 
								'minimumIntegerDigits', 
								'minimumFractionDigits', 
								'maximumFractionDigits', 
								'minimumSignificantDigits', 
								'maximumSignificantDigits', 
								'value', 
								function() {
		let options = {};

		Object.assign(options,  
			this.style && {style: this.style},
			this.localeMatcher && {localeMatcher: this.localeMatcher},
			this.currency && {currency: this.currency},
			this.currencyDisplay && {currencyDisplay: this.currencyDisplay},
			this.useGrouping && {useGrouping: this.useGrouping},
			this.minimumIntegerDigits && {minimumIntegerDigits: this.minimumIntegerDigits},
			this.minimumFractionDigits && {minimumFractionDigits: this.minimumFractionDigits},
			this.maximumFractionDigits && {maximumFractionDigits: this.maximumFractionDigits},
			this.minimumSignificantDigits && {minimumSignificantDigits: this.minimumSignificantDigits},
			this.maximumSignificantDigits && {maximumSignificantDigits: this.maximumSignificantDigits}
		);


		if(this.locales){
			return new Intl.NumberFormat(this.locales, options).format(this.value);
		} else {
			return new Intl.NumberFormat(undefined, options).format(this.value);
		}
	})
	
});
