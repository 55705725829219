import OAuth2ImplicitGrantAuthenticator from 'ember-simple-auth/authenticators/oauth2-implicit-grant';
import RSVP from 'rsvp';

export default OAuth2ImplicitGrantAuthenticator.extend({
	authenticate(hash) {
    return new RSVP.Promise((resolve, reject) => {
      if (hash.error) {
        reject(hash.error);
      } else if (!this._validateData(hash)) {
        reject('Invalid auth params - "access_token" missing.');
      } else {
				resolve(hash);
      }
    });
  },
});
