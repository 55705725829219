import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span style={{color}}>{{format-number percentile '0%'}}</span>\n", {"contents":"<span style={{color}}>{{format-number percentile '0%'}}</span>\n","moduleName":"agropur-client/components/vd-display-percentile.hbs","parseOptions":{"srcName":"agropur-client/components/vd-display-percentile.hbs"}});
import Component from '@ember/component';
import { isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default Component.extend({

  inverted: false,


  percentile: computed('price', 'range', function() {
    let value = 0;
    if (isPresent(this.price) && isPresent(this.get('range'))) {
      let index = null;
      this.get('range').forEach((el, idx) => {
        if (el >= this.price && index === null) {
          index = idx;
        }
      });
      if (index === null) {
        value = 1;
      } else {
        value = index / this.get('range').length;
      }
    }
    return value;
  }),

  color: computed('percentile', function() {
    let color1;
    let color2;
    if (this.get('inverted')) {
      color2 = '008000';
      color1 = 'FF0000';
    } else {
      color1 = '008000';
      color2 = 'FF0000';
    }

    const r = Math.ceil(parseInt(color1.substring(0, 2), 16) * this.get('percentile') + parseInt(color2.substring(0, 2), 16) * (1 - this.get('percentile')));
    const g = Math.ceil(parseInt(color1.substring(2, 4), 16) * this.get('percentile') + parseInt(color2.substring(2, 4), 16) * (1 - this.get('percentile')));
    const b = Math.ceil(parseInt(color1.substring(4, 6), 16) * this.get('percentile') + parseInt(color2.substring(4, 6), 16) * (1 - this.get('percentile')));

    const riskyString = `color: #${this._colorHex(r)}${this._colorHex(g)}${this._colorHex(b)} !important;`;
    return htmlSafe(riskyString);
  }),

  _colorHex(x) {
    const val = x.toString(16);
    return (val.length === 1) ? `0${val}` : val;
  }
});
