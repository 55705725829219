import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if flashPrice}}\n<span class=\"value-changed-transition\">${{value}}</span>\n{{else}}\n{{value}}\n{{/if}}", {"contents":"{{#if flashPrice}}\n<span class=\"value-changed-transition\">${{value}}</span>\n{{else}}\n{{value}}\n{{/if}}","moduleName":"agropur-client/components/vault-price.hbs","parseOptions":{"srcName":"agropur-client/components/vault-price.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object'
import { isPresent } from '@ember/utils';

export default Component.extend({
    flashPrice: false,
    instrument: null,
    price: null,

    value: computed('instrument', 'instrument.{bestPrice,product.tick_size}', 'price', function(){

        if(isPresent(this.instrument) && (isPresent(this.instrument.bestPrice) || isPresent(this.price))&& isPresent(this.instrument.product.get('tick_size'))){
            let fmt = this.instrument.product.get("rdFormat") === undefined ? "0,0.00" : this.instrument.product.get("rdFormat");
            let multiple = this.instrument.product.get("rdMultiple") === undefined ? 1 : this.instrument.product.get("rdMultiple");

            let price = this.price != undefined && this.price != null ? this.price : this.instrument.bestPrice;
              // eslint-disable-next-line no-undef
              let dec = new BigNumber(price).dividedBy(this.instrument.product.get("tick_size")).times(this.instrument.product.get("tick_value")).times(multiple).toNumber();
              // eslint-disable-next-line no-undef
              return numeral(dec).format(fmt);

        } else {
            return "-"
        }

    })


});
