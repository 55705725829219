import DS from "ember-data";
import moment from "moment";

const { JSONSerializer } = DS;

export default JSONSerializer.extend({
	extractId(modelClass, resourceHash) {
		resourceHash.id = resourceHash.symbol;
		return this._super(modelClass, resourceHash);
	},
	normalizeResponse: function(
		store,
		primaryModelClass,
		payload,
		id,
		requestType
	) {
		payload = payload.results;

		let cutoffTime;
		if(moment().utc().hour() >= 22){
			cutoffTime = moment.utc().hour(22).minute(0).second(0).millisecond(0);
		} else {
			cutoffTime = moment.utc().hour(22).minute(0).second(0).millisecond(0).subtract(1, 'day');
		}

		payload.forEach(quote => {
			if(quote.flag == "s" || moment(quote.tradeTimestamp).isBefore(cutoffTime)){
				quote.netChange = 0;
				quote.percentChange = 0;
				quote.high = 0;
				quote.low = 0;
			}
		});

		return this._super(store, primaryModelClass, payload, id, requestType);
	},
	normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
		if (Array.isArray(payload)) {
			payload = payload[0];
		}

		if (payload === undefined) {
			payload = {};
		}

		return this._super(store, primaryModelClass, payload, id, requestType);
	}
});
