import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import ENV from 'agropur-client/config/environment';
import { merge } from '@ember/polyfills';
import {isEmpty} from '@ember/utils';
import jwt_decode from 'jwt-decode';
import $ from 'jquery';

export default OAuth2PasswordGrant.extend({
    serverTokenEndpoint: `${ENV.APP.VAULT_API}/oauth/token`,
    serverTokenRevocationEndpoint: `${ENV.APP.VAULT_API}/logout`,

    /**
    Makes a request to the OAuth 2.0 server.

    @method makeRequest
    @param {String} url The request URL
    @param {Object} data The request data
    @param {Object} headers Additional headers to send in request
    @return {Promise} A promise that resolves with the response object
    @protected
  */
  makeRequest(url, data, headers = {}) {
    headers['Content-Type'] = headers['Content-Type'] ? headers['Content-Type'] : 'application/x-www-form-urlencoded';

    const body = Object.keys(data).map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    }).join('&');

    const options = {
      body,
      headers,
      method: 'POST'
    };

    const clientIdHeader = this.get('_clientIdHeader');
    if (!isEmpty(clientIdHeader)) {
      merge(options.headers, clientIdHeader);
    }

    return new Promise((resolve, reject) => {

      fetch(url, options).then((response) => {
        response.text().then((text) => {
          try {
            let json = JSON.parse(text);
            if (!response.ok) {
              response.responseJSON = json;
              reject(response);
            } else {

              resolve(json);

            }
          } catch (SyntaxError) {
            console.log('err');
            response.responseText = text;
            reject(response);
          }
        });
      }).catch(reject);
    });
  },
});