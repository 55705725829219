import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default Route.extend(AuthenticatedRouteMixin, {
    model() {
        let instruments = this.store.peekAll('modern-instrument');

        return RSVP.hash({

            classThreeFutures: instruments.filter((i) => {
                return i.name.includes("Class III") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 12),
            classFourFutures: instruments.filter((i) => {
                return i.name.includes("Class IV") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 12),
            dryWheyFutures: instruments.filter((i) => {
                return i.name.includes("Dry Whey") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 12),
            cashSettledCheeseFutures: instruments.filter((i) => {
                return i.name.includes("Cheese") && !i.name.includes('Block') && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 12),
            nonfatDryMilkFutures: instruments.filter((i) => {
                return i.name.includes("Nonfat Milk") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 12),
            cashSettledButterFutures: instruments.filter((i) => {
                // This isn't very robust.
                return i.name.includes("Butter") && !i.name.includes('European') && !i.name.includes('Global') && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 12),
            cornFutures: instruments.filter((i) => {
                return i.name.includes("Corn") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 7),
            soybeanMealFutures: instruments.filter((i) => {
                return i.name.includes("Soybean Meal") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 7),
            soybeanFutures: instruments.filter((i) => {
                return i.name.includes("Soybeans") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 7),
            wheatFutures: instruments.filter((i) => {
                return i.name.includes("Chicago Soft Red Winter Wheat") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 7),
            liveCattleFutures: instruments.filter((i) => {
                return i.name.includes("Live Cattle") && i.productType == 'Future';
            }).sortBy('displayExpiresAt').slice(0, 7),

            cheeseBarrel: this.store.findRecord('barchart-market-datum', 'C3Y00'),
            cheeseBlock: this.store.findRecord('barchart-market-datum', 'C4Y00'),
            spotButter: this.store.findRecord('barchart-market-datum', 'C5Y00'),
            spotNonfat: this.store.findRecord('barchart-market-datum', 'C4Y00')

        });

    },
});
