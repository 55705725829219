import ENV from "agropur-client/config/environment";
import { computed } from "@ember/object";
import $ from "jquery";
import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { alias } from "@ember/object/computed";

export default Controller.extend({
    ENV,
    store: service(),

    session: service(),

    dairies: alias("model.dairies"),

    init() {
        this._super(...arguments);
        this.set("searchAttrs", ["name", "producer", "contact", "location"]);
    },

    searchAttr: "name",
    searchTerm: "",

    queryParams: [
        "sortProperties", "sortAscending", "searchAttr", "searchTerm"
    ],

    sortProperties: "name",
    sortAscending: true,

    sortProperty: computed("sortProperties", function () {
        return [this._getSortProperty(this.get("sortProperties"))];
    }),

    sortName: computed("sortProperties", function () {
        switch (this.sortProperties) {
            case "name":
                return "Name";
            case "organization":
                return "Organization";
            case "producerId":
                return "Producer ID";
            case "primary":
                return "Primary Contact";
            case "location":
                return "Location";
        }
    }),

    _getSortProperty(sortProperties) {
        let property = "";

        switch (sortProperties) {
            case "name": property = "name";
                break;
            case "producerId": property = "producerId";
                break;
            case "primary": property = "primaryContact.fullName";
                break;
            case "location": property = "cityState";
                break;
        }

        return property || "";
    },

    dairySorting: computed("sortProperty", "sortAscending", function () {
        let sortProperty = this.get("sortProperty");
        sortProperty = typeof sortProperty === "string" ? new Array(sortProperty) : sortProperty;
        const sort = this.get("sortAscending") ? sortProperty[0] : sortProperty[0].concat(":desc");
        return new Array(sort);
    }),

    sortedDairies: computed.sort("filteredDairies", "dairySorting"),

    _getTestString(dairy, searchAttr) {
        let testString = "";

        switch (searchAttr) {
            case "name": testString = dairy.get("name");
                break;
            case "producer": testString = dairy.get("config.producerId");
                break;
            case "contact": testString = dairy.get("primaryContact.fullName");
                break;
            case "location": testString = dairy.get("cityState");
                break;
        }

        return testString || "";
    },

    filteredDairies: computed("dairies.[]", "searchAttr", "searchTerm", "application.dairy", function () {
        const searchAttr = this.get("searchAttr");
        const searchTerm = this.get("searchTerm");

        return this.get("dairies").filter(function (dairy) {
            const testString = searchTerm.length > 0 ? this._getTestString(dairy, searchAttr, searchTerm) : "";
            return testString.toLowerCase().includes(searchTerm.toLowerCase());
        }, this);
    }),

    _addDairy(newDairyModel) {

        let { access_token } = this.get('session.data.authenticated');
        const members = this.get("newDairyModelMembers").mapBy("id");
        const data = {
            organizationId: this.get("model.organization.id"),
            dairy: newDairyModel.toJSON(),
            members
        };

        return $.ajax({
            type: "POST",
            url: `${ENV.APP.HOST}/commercial_api/api/dairies`,
            contentType: "application/json",
            context: this,
            processData: false,
            headers: {
                'Authorization': `Bearer ${access_token}`
            },
            data: JSON.stringify(data)
        }).catch(() => {
            return;
        }).then(() => {
            this.send("refreshModel");
        });
    },

    actions: {
        toggleCreateModal() {
            if (!this.get("showCreateModal")) {
                this.toggleProperty("showCreateModal");
                this.set("newDairyModel", this.store.createRecord('dairy'));
                this.set("newDairyModelMembers", []);
            } else {
                this.toggleProperty("showCreateModal");
                this.get("newDairyModel").deleteRecord();
                this.set("newDairyModelMembers", []);
            }
        },

        saveDairy(dairy) {
            this._addDairy(dairy);
            this.toggleProperty("showCreateModal");
        },

        rollbackDairy(dairy) {
            this.toggleProperty("showCreateModal");
            dairy.deleteRecord();
        },

        deleteDairy(dairy) {
            const label = dairy.get("name");
            const shouldCallSetup = dairy === this.get("application.dairy");

            dairy.destroyRecord();

            this.get("application.notification").success(`${label} has beed deleted`);
            if (shouldCallSetup) {
                window.location.reload();
            }
        },

        addUserDairyConfig(type, users) {
            this.set(`newDairyModel${type}s`, users);
        }
    }
});
