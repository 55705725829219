import ENV from 'agropur-client/config/environment';

export default function vaultDisplayPrice(price, rootSymbol, currency) {

    if (! rootSymbol || ! price) {
        return price;
    }

    const priceFormat = ENV.APP.PRICE_FORMAT[rootSymbol];
    const modifiedPrice = price * priceFormat.displayFactor;

    if (currency) {
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: priceFormat.fractionalDecimals,
            maximumFractionDigits: priceFormat.fractionalDecimals
        }).format(modifiedPrice);
    } else {
        return Intl.NumberFormat('en-US', {
            minimumFractionDigits: priceFormat.fractionalDecimals,
            maximumFractionDigits: priceFormat.fractionalDecimals
        }).format(modifiedPrice);
    }

}
