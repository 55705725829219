
import { cancel, later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import ingredient from '../models/ingredient';

export default Service.extend({
  session: service(),
  store: service(),
  pollTimer: null,
  pollFrequency: 2500,
  subscribedInstruments: null,

  init: function(){
      this._super(...arguments);
      this.subscribedInstruments = [];
  },
  start: function(/*subscriber, */){
    if(this.get("pollTimer") !== null){
      cancel(this.get("pollTimer"));
    }
    this.marketDataPoll();
  },
  stop: function(/*subscriber*/){
    cancel(this.get("pollTimer"));
  },
  subscribeTo: function(instruments){
    this.set('subscribedInstruments', instruments);
  },
  marketDataPoll: function(){

    const instruments = this.get('subscribedInstruments');
    let instrumentId;
    instruments.forEach(i => {
      instrumentId = i.get('barchartSymbol.id');
      if(instrumentId){
        this.store.findRecord("barchart-market-datum", instrumentId);
      }

    });

    this.set("pollTimer", later(this, this.marketDataPoll.bind(this), this.get("pollFrequency")));

  }
});
