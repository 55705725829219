import ApolloService from "ember-apollo-client/services/apollo";
import { createHttpLink } from 'apollo-link-http';
import { inject as service } from "@ember/service";
import { isPresent } from '@ember/utils';
import { onError } from "apollo-link-error";

export default ApolloService.extend({
  session: service(),

  link() {
    const { apiURL, requestCredentials } = this.options;
    const linkOptions = { uri: apiURL, fetch};
    const token = this.session.get("data.authenticated.access_token");

    if (isPresent(requestCredentials)) {
      linkOptions.credentials = requestCredentials;
    }

    if (isPresent(token)) {
        linkOptions.headers = {
            Authorization: `Bearer ${token}`
        };
    }

    // Afterware
    const errorLink = onError(({ networkError }) => {

      if (networkError && (networkError.statusCode === 400 || networkError.statusCode === 401)) {
        this.get('session').invalidate();
      }
    });

    return errorLink.concat(createHttpLink(linkOptions));
  }
});