import ENV from 'agropur-client/config/environment';
import moment from 'moment';
import DateUtils from 'agropur-client/utils/date-utils';
import { computed } from '@ember/object';
import Service from '@ember/service';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import EmberObject from '@ember/object';
import { A } from '@ember/array';

const { currentRollbackMonth, format } = DateUtils;

/**
 * Month Serice provides the list of filtered months by date.<br />
 * It shares the state thru the entire application
 * @module OrganizationMonthService
 * @extends Ember.Service
 * @class OrganizationMonthService
 * @author David M
 */
export default Service.extend({
  store: service(),
  application: service(),
  session: service(),

  rollAfterDay: ENV.APP.MONTH.ROLL_AFTER,
  dateFormat: ENV.APP.DATE.FORMAT,
  currentDate: null,
  displayMonth: null,

  /**
   * Number of months to display.<br >
   * Default value is taken from Eviroment configuration.<br />
   * <pre>ENV.APP.MONTH.TO_SHOW</pre>
   * @property showRange
   * @type {Number}
   * @default 24
   */
  showRange: ENV.APP.MONTH.TO_SHOW,
  currentMonths: null,
  showLoading: false,

  // organizationMonths: [],

  _loadOrganizationMonths(organizationId) {
    let { access_token } = this.get('session.data.authenticated');
    return $.ajax({
      type: 'GET',
      url: `${ENV.APP.AGGREGATION.HOST}/api/aggregation/organization/${organizationId}`,
      contentType: 'application/json',
      dataType: 'json',
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    }).catch(() => {
      return;
    }).then((payload) => {
      return this.get('store').pushPayload({ organizationMonth: payload });
    });
  },

  init() {
    this._super(...arguments);
    const now = format(currentRollbackMonth(), this.get('dateFormat'));
    this.set('displayMonth', now);

    const today = format(moment().utc(), this.get('dateFormat'));
    this.set('tradeDate', today);

    this.set('filteredOrganizationMonthsSorting', ['date']);
    this.set('tradeLogSorting', ['id']);
  },

  filteredOrganizationMonths: null,



  orderedFilteredOrganizationMonths: sort('organizationMonths', 'filteredOrganizationMonthsSorting'),



  sortedDaysWithPositions: sort('daysWithPositions', 'tradeLogSorting'),

  tradeLogClassThreeMilk: true,
  tradeLogAgropurBasis: true,

  activeTradeDate: computed('sortedDaysWithPositions', 'displayMonth', function () {
    return this.get('sortedDaysWithPositions').filter((day) => {
      return moment.utc(day.get('id')).isSame(moment.utc(this.get('displayMonth')), 'month'); // Month
    });
  }),

  daysWithPositions: computed('orderedFilteredOrganizationMonths', 'tradeLogClassThreeMilk', 'tradeLogAgropurBasis', function () {
    const list = [];
    // Temp cache
    const tradeDayMap = {};

    const TradeDay = EmberObject.extend({
      trades: A
    });

    const Trade = EmberObject.extend({

      expirationDate: computed('expiration', function () {
        // return format(expiration, YEAR_MONTH);
        return format(this.get('expiration'), 'YYYY-MM-DD');
      }),

      contractMonth: computed('expiration', function () {
        return format(this.get('expiration'), 'MMM YYYY');
      })
    });

    this.get('orderedFilteredOrganizationMonths').forEach((m) => {
      m.get('dairyArray').forEach((d) => {
        const positions = d.get('positions');

        positions.forEach((p) => {
          if ((p.get('productId') === 'DC') || (p.get('productId') === 'BASIS') || (p.get('productId') === 'NEW_BASIS')  ) {
            return;
          }
          const date = moment(p.timestamp).utc();
          const day = date.format('DD');
          const month = date.format('MM');
          const year = date.format('YYYY');

          const tradeDayId = `${year}-${month}-${day}`;

          const t = Trade.create({
            tradeDayId,

            producerId: parseInt(d.producerId, 10),
            dairyName: d.name,

            expiration: m.get('date'),

            quantity: p.quantity,
            timestamp: p.timestamp,
            strike: p.strike,
            price: p.tradePrice,
            isLong: p.isLong,
            id: p.id,
            monthId: p.monthId,
            productId: p.productId,
            userId: p.userId,
            positionType: p.positionType,
            type: p.type,
            deleted: p.deleted
          });

          if (!tradeDayMap[tradeDayId]) {
            tradeDayMap[tradeDayId] = TradeDay.create({
              id: tradeDayId,
              trades: [t]
            });
            list.push(tradeDayMap[tradeDayId]);
          } else {
            tradeDayMap[tradeDayId].get('trades').push(t);
          }
        });
      });
    });
    return list;
  }),


  activeMonth: computed('orderedFilteredOrganizationMonths', 'displayMonth', function () {
    return this.get('orderedFilteredOrganizationMonths').filter((month) => {
      return moment.utc(month.get('date')).isSame(moment.utc(this.get('displayMonth')), 'month');
    });
  }),

  activeMonthList: computed('orderedFilteredOrganizationMonths', 'displayMonth', function () {
    const plusTwentyFourMonths = format(moment.utc(this.get('displayMonth')).add(24, 'months'), this.get('dateFormat'));
    return this.get('orderedFilteredOrganizationMonths').filter((month) => {
      return moment.utc(month.get('date')).isBetween(this.get('displayMonth'), plusTwentyFourMonths, 'month', '[)');
    });
  }),

  organizationMonths: computed('', function () {
    const organization_id = this.get('application.organization.id');
    const months = this.get('store').peekAll('organization-month').filter((m) => {
      return m.get('organizationId.id') === organization_id;
    });
    return months;
  }),

  setActiveOrganizationMonth(next, count, time, reset = false) {
    if (reset) {
      const now = format(currentRollbackMonth(), this.get('dateFormat'));
      this.set('displayMonth', now);
    } else {
      const current = moment.utc(this.get('displayMonth'));
      if (next) {
        this.set('displayMonth', format(current.add(count, time), this.get('dateFormat')));
      } else {
        this.set('displayMonth', format(current.subtract(count, time), this.get('dateFormat')));
      }
    }
  },

  toggleTradeLogFilter(property) {
    this.set(property, !this.get(property));
  },

  allDairiesExpanded: false,
  allMonthsExpanded: false,

  dsiExportData: computed('activeMonth', function () {
    const data = {
      filename: null,
      rows: []
    };
    const month = this.get('activeMonth.firstObject');

    const division = month.get('organizationId.content.division');
    const date = moment.utc(month.get('date'));
    const m = format(date, 'M');
    const y = format(date, 'YY');
    const updatedAt = moment.utc();

    month.get('dairyArray').forEach((d) => {
      const classThree = d.get('totalClassThreeMilkNetHedgeAdjustment');
      const basis = d.get('totalAgropurBasisNetHedgeAdjustment');
      const positions = d.get('positions');

      // if (classThree !== 0 || basis !== 0) { // If you only want to see adjustments
      if (positions.length > 0) { // If you want to see every dairy with positions
        const row = [division, d.get('id'), m, y, classThree.toFixed(2), basis.toFixed(2)];
        data.rows.push(row);
      }
    });

    data.filename = `${format(updatedAt, 'YYYYMMDD_HHmmss')}_${division.split(' - ')[1].toUpperCase()}_${format(date, 'MMYYYY')}`;

    return data;
  }),


  classIIIAndBasisPLExport: computed('activeMonth', function () {
    const data = {
      filename: null,
      rows: []
    };

    const header = ['Producer Number', 'Producer', 'Month', 'Product', 'Bought/Sold', 'Contracted(lbs)', 'Type', 'Strike', 'Trade Price', 'Settlement/Actual', 'Gain/Loss ($/cwt)', 'Class III Adjustment', 'Basis Adjustment'];
    data.rows.push(header);

    const month = this.get('activeMonth.firstObject');

    const date = moment.utc(month.get('date'));
    const m = format(date, 'MMM YYYY');

    month.get('dairyArray').forEach((d) => {
      const positions = d.get('positions');

      if (positions.length > 0) { // If you want to see every dairy with positions

        const producerId = d.get('id');
        const producer = d.get('name').replace(',', ' ');

        let classThree = Number(d.get('totalClassThreeMilkNetHedgeAdjustment'));
        let basis = Number(d.get('totalAgropurBasisNetHedgeAdjustment'));
        let netHedgeAdjustment = Number(d.get('totalAgropurBasisNetHedgeAdjustment'));
        let poundsHedged = Number(d.get('totalCombinedPoundsHedged'));

        for (let i = 0; i < positions.length; i++) {
          const p = positions[i];
          const product = p.get('productId');
          const productFormatted = product === 'BASIS' ? 'Agropur Basis' : 'Class III Milk';
          netHedgeAdjustment = Number(p.get('netHedgeAdjustment')).toFixed(2);
          classThree = product === 'BASIS' ? '' : Number(netHedgeAdjustment).toFixed(2);
          basis = product === 'BASIS' ? Number(netHedgeAdjustment).toFixed(2) : '';
          poundsHedged = Number(p.get('quantity'));
          const side = p.get('isLong') ? 'Bought' : 'Sold';
          const type = p.get('type');
          const strike = type !== 'FORWARD' ? Number(p.get('strike')).toFixed(2) : '';
          const tradePrice = Number(p.get('tradePrice')).toFixed(2);
          const productPrice = Number(p.get('productPrice')).toFixed(2);
          const hedgePL = Number(p.get('hedgePL')).toFixed(2);

          const positionRow = [producerId, producer, m, productFormatted, side, poundsHedged, type, strike, tradePrice, productPrice, hedgePL, classThree, basis];
          data.rows.push(positionRow);
        }
      }
    });

    data.filename = month.get('organizationId.content.name').split(' ').join('_');
    return data;
  }),


  weightedAvgBasisPriceExport: computed('activeMonth', function () {
    const data = {
      filename: null,
      rows: []
    };
    const header = ['Month', 'Producer Number', 'Producer', 'Base Volume', 'Basis Contracted(lbs)', 'Basis Contracted(%)', 'Weighted Average Price', 'Current Offer'];
    data.rows.push(header);

    const month = this.get('activeMonth.firstObject');

    const date = moment.utc(month.get('date'));
    const m = format(date, 'MMM YYYY');

    const agropurBasisPrice = month.get('agropurBasisPrice');

    month.get('dairyArray').forEach((d) => {
      const producerId = d.get('id');
      const producer = d.get('name').replace(',', ' ');

      const totalProduction = d.get('totalProduction');
      const totalAgropurBasisPoundsHedged = d.get('totalAgropurBasisPoundsHedged');
      const totalAgropurBasisPercentageHedged = d.get('totalAgropurBasisPercentageHedged');
      const totalAgropurBasisHedgePrice = d.get('totalAgropurBasisHedgePrice');

      const dairyRow = [m, producerId, producer, totalProduction.toFixed(0), totalAgropurBasisPoundsHedged.toFixed(0), totalAgropurBasisPercentageHedged.toFixed(4), totalAgropurBasisHedgePrice.toFixed(2), agropurBasisPrice.toFixed(2)];
      data.rows.push(dairyRow);
    });

    //data.filename = `${format(updatedAt, 'YYYYMMDD_HHmmss')}_${division.split(' - ')[1].toUpperCase()}_${format(date, 'MMYYYY')}`;
    data.filename = month.get('organizationId.content.name').split(' ').join('_');
    return data;
  }),

  percentageHedgedExport: computed('activeMonth', function () {
    const data = {
      filename: null,
      rows: []
    };
    const header = ['Month', 'Producer Number', 'Producer', 'Base Volume', 'Actual Production', 'Class III Contracted(lbs)', 'Class III Contracted(%)', 'Basis Contracted(lbs)', 'Basis Contracted(%)'];
    data.rows.push(header);

    const month = this.get('activeMonth.firstObject');

    const date = moment.utc(month.get('date'));
    const m = format(date, 'MMM YYYY');

    month.get('dairyArray').forEach((d) => {
      const producerId = d.get('id');
      const producer = d.get('name').replace(',', ' ');

      const totalProduction = d.get('totalProduction');
      const actualProduction = d.get('actualProduction');
      const totalClassThreeMilkPoundsHedged = d.get('totalClassThreeMilkPoundsHedged');
      const totalClassThreeMilkPercentageHedged = d.get('totalClassThreeMilkPercentageHedged');
      const totalAgropurBasisPoundsHedged = d.get('totalAgropurBasisPoundsHedged');
      const totalAgropurBasisPercentageHedged = d.get('totalAgropurBasisPercentageHedged');

      const dairyRow = [m, producerId, producer, totalProduction.toFixed(0), actualProduction.toFixed(0), totalClassThreeMilkPoundsHedged.toFixed(0), totalClassThreeMilkPercentageHedged.toFixed(4), totalAgropurBasisPoundsHedged.toFixed(0), totalAgropurBasisPercentageHedged.toFixed(4)];
      data.rows.push(dairyRow);
    });

    data.filename = month.get('organizationId.content.name').split(' ').join('_');
    return data;
  }),

  productionReportExport: computed('activeMonth', function () {
    const data = {
      filename: null,
      rows: []
    };
    const header = ['Month', 'Producer Number', 'Producer', 'Base Volume', 'Actual Production', 'Difference(lbs)', 'Difference(%)'];
    data.rows.push(header);

    const month = this.get('activeMonth.firstObject');

    const date = moment.utc(month.get('date'));
    const m = format(date, 'MMM YYYY');


    month.get('dairyArray').forEach((d) => {
      const producerId = d.get('id');
      const producer = d.get('name').replace(',', ' ');

      const totalProduction = d.get('totalProduction');
      const actualProduction = d.get('actualProduction');
      const productionDifference = d.get('productionDifference');
      const productionPercentageDifference = d.get('productionPercentageDifference');

      const dairyRow = [m, producerId, producer, totalProduction.toFixed(0), actualProduction.toFixed(0), productionDifference.toFixed(0), productionPercentageDifference.toFixed(4)];
      data.rows.push(dairyRow);
    });

    data.filename = month.get('organizationId.content.name').split(' ').join('_');
    return data;
  }),


  tradeLogExport: computed('activeTradeDate', 'activeMonth', function () {
    const data = {
      filename: null,
      rows: []
    };

    const header = ['Trade Date', 'Producer Number', 'Producer', 'User ID', 'Contract Month', 'Product', 'Bought/Sold', 'Contracted(lbs)', 'Type', 'Strike', 'Trade Price'];
    data.rows.push(header);

    const month = this.get('activeMonth.firstObject');

    this.get('activeTradeDate').forEach((day) => {
      day.get('trades').forEach((t) => {
        const timestamp = moment.utc(t.get('timestamp')).format('MM/DD/YYYY');
        const producerId = t.get('producerId');
        const producer = t.get('dairyName').replace(',', ' ');
        const userId = t.get('userId');
        const expiration = moment.utc(t.get('expiration')).format('MMM YYYY');
        const productId = t.get('productId') === 'DC' ? 'Class III Milk' : 'Agropur Basis';
        const isLong = t.get('isLong') ? 'Bought' : 'Sold';
        const quantity = t.get('quantity');
        const type = t.get('type') === 'FORWARD' ? 'FORWARD' : t.get('type');
        const strike = t.get('strike') || '';
        const price = t.get('price');

        const tradeRow = [timestamp, producerId, producer, userId, expiration, productId, isLong, quantity.toFixed(0), type, strike, price.toFixed(2)];
        data.rows.push(tradeRow);
      });
    });

    data.filename = month.get('organizationId.content.name').split(' ').join('_');
    return data;
  })
});
