import DateUtils from "agropur-client/utils/date-utils";
import { computed } from "@ember/object";
import Controller from "@ember/controller";
import { mapBy, sum } from "@ember/object/computed";
import PeriodMixin from "agropur-client/mixins/period";
import ENV from "agropur-client/config/environment";

const { currentRollbackMonth, toUtc } = DateUtils;

export default Controller.extend(PeriodMixin, {
	ENV,
	currentDate: null,

	init() {
		this._super(...arguments);
		this.set("currentDate", toUtc(currentRollbackMonth()));
		this.set("positionsSorting", ["instrument.product.name", "month.date"]);
	},

	symbols: computed("isAgropur", function() {
		return ["BASIS", "NEW_BASIS", "DC"];
	}),

	positionsPLMap: mapBy("model.manualPositions", "plInDollars"),
	totalPL: sum("positionsPLMap")
});
