import { computed } from '@ember/object';
import Controller from '@ember/controller';
import { filterBy } from '@ember/object/computed';
import PeriodMixin from 'agropur-client/mixins/period';
import DisplayModeMixin from 'agropur-client/mixins/display-mode';

export default Controller.extend(PeriodMixin, DisplayModeMixin, {


  monthsList: computed('months', 'months.@each.agropurBasisPrice', function () {

    return this.get('months').map(m => ({

      id: m.get('id'),
      date: m.get('date'),
      month: m,
      checked: false,

      basisValue: m.get('agropurBasisPrice')

    }));
  }),

  checkAll: computed('monthsList', {
    get() {
      return false;
    },
    set(checked, monthsList) {
      monthsList.forEach(el => el.set('checked', checked));
      return checked;
    }
  }),

  checkedMonths: filterBy('monthsList', 'checked', true),

  actions: {
    saveVaultBasis() {
      const list = this.get('checkedMonths');
      const value = this.get('isagropurBasisPrice') ? this.get('application.dairy.vaultBasis.value') : this.get('monthService.basisAverageMonth.vaultBasisValue');

      if (list.length === 0) {
        this.get('notification').info('No months selected');
      } else {
        list.forEach((el) => {
          const month = el.get('month');
          month.set('agropurBasisPrice', value);
          month.save();
        });
        this.toggleProperty('showVaultBasisModal');
        this.set('checkAll', false);
        this.get('notification').success('Vault Basis Saved');
      }
    }
  }
});
