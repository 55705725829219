import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {

    model(params) {
        const { dairy, basisInstruments, spotInstruments, currentUserId, isOrgAdmin } = this.modelFor('authenticated');
        return hash({
            manualPositions: this.get('store').query('manual-position', {
                dairy_id: dairy.id,
            }).then(results => results.filter((position) => {
																return position.get('product_id') === 'NEW_BASIS' || position.get('product_id') === 'BASIS';
													})),
            month: this.get('store').findRecord('month', params.id),
            basisInstruments,
												spotInstruments,
            currentUserId,
            isOrgAdmin,
            dairy
        });
    },
    afterModel(model) {
        if (model.month.get('dairy.id') != model.dairy.get('id')) {
            this.transitionTo('authenticated.class-iii-hedges');
        }

        let basisInstrument = model.basisInstruments.find((e) => {
            return moment.utc(e.get('expiration')).isSame(model.month.date, 'month');
        });

								let spotInstrument = model.spotInstruments.find((e) => {
									return moment.utc(e.get('expiration')).isSame(model.month.date, 'month');
					});
        model.month.set('agropurBasisInstrument', basisInstrument);
								model.month.set('spotBasisInstrument', spotInstrument);

    },
    actions: {
        refreshModel() {
            this.refresh();
        }
    }
});
