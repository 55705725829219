import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"btn-group btn-group-sm visible-md visible-lg\" role=\"group\">\n    <button class=\"btn btn-default btn-sm\" {{action \"print\"}}>{{fa-icon 'print'}}</button>\n</div>", {"contents":"<div class=\"btn-group btn-group-sm visible-md visible-lg\" role=\"group\">\n    <button class=\"btn btn-default btn-sm\" {{action \"print\"}}>{{fa-icon 'print'}}</button>\n</div>","moduleName":"agropur-client/components/print-button.hbs","parseOptions":{"srcName":"agropur-client/components/print-button.hbs"}});
import Component from '@ember/component';

export default Component.extend({
    actions: {    
        print() {
            window.print();
        }
    }
});
