import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import {hash} from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {

    model(params) {
        const {dairy, currentUserId, isOrgAdmin} = this.modelFor('authenticated');
        return hash({
            manualPositions: this.get('store').query('manual-position', {
                dairy_id: dairy.id,
                product_id: 'DC'
            }),
            month: this.get('store').findRecord('month', params.id),
            classThreeFutures: this.store.peekAll('modern-instrument').filter((i) => {
                return i.name.includes("Class III") && i.productType == 'Future';
              }).sortBy('displayExpiresAt'),
            currentUserId,
            isOrgAdmin,
            dairy
        });
    },
    afterModel(model) { // Make sure the dairy and month hasn't diverged.
        if (model.month.get('dairy.id') != model.dairy.get('id')) {
            this.transitionTo('authenticated.class-iii-hedges');
        }

        let instrument = model.classThreeFutures.find((e) => {
            return moment(e.get('displayExpiresAt')).isSame(model.month.date, 'day');
        });

        model.month.set('classThreeMilkInstrument', instrument);

    },
    actions: {
        refreshModel() {
            this.refresh();
        }
    }
});
