import Route from "@ember/routing/route";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import { hash } from "rsvp";
import moment from "moment";
import { inject as service } from "@ember/service";
import productGroupsQuery from 'agropur-client/graphql/queries/product-groups';
import ENV from 'agropur-client/config/environment';

export default Route.extend(AuthenticatedRouteMixin, {
	apollo: service(),
	marketDataPolling: service("market-data"),
	queryParams: {
		period: {
			refreshModel: true
		},
		dairy_id: {
			refreshModel: true
		},
		organization_id: {
			refreshModel: true
		},
		display_mode: {
			refreshModel: true
		}
	},
	model(params) {
		let periodDate = moment(params.period, "YYYYMM");

		return hash({
			dairies: this.get("store").findAll("dairy"),
			dairy: params.dairy_id
				? this.get("store").findRecord("dairy", params.dairy_id)
				: null,
			userOrganizationConfigs: this.get("store").query(
				"user-organization-config",
				{ organization_id: params.organization_id }
			),
			organizations: this.get("store").findAll("organization"),
			organization: params.organization_id
				? this.get("store").findRecord("organization", params.organization_id)
				: null,
			periodDate,
			user: fetch(`${ENV.APP.HOST}/commercial_api/api/users/me`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${this.get('session.data.authenticated.access_token')}`
				}
			}).then(response => { return response.json() }),
			displayMode: params.display_mode,
			productGroups: this.apollo.query(
				{ query: productGroupsQuery }
			),
			basisProduct: this.get("store").query("vault-product", { symbols: "BASIS" }),
			allBasisInstruments: this.get("store").findAll("vault-instrument"),
			historicalPrices: this.get("store").query("historical-price", {
				from: moment(periodDate)
					.subtract(1, "month")
					.format("YYYY-MM-DD"),
				to: moment(periodDate)
					.add(2, "years")
					.format("YYYY-MM-DD")
			}),
			endProductBasisProduct: this.get("store").query("vault-product", { symbols: "UNCONTRACTED_BASIS" }),
			spotProduct: this.get("store").query("vault-product", { symbols: "NEW_BASIS" }),
		});
	},
	afterModel(model) {
		if (model.productGroups) {
			let productGroups = [];
			let products = [];
			let instruments = [];
			let mostCurrentFutures = [];
			let currentFutures = {};
			let milkCutoffDate = moment().subtract(15, 'days');
			model.productGroups.ProductGroups.forEach((productGroup) => {

				productGroups.push({ id: productGroup.id, description: productGroup.description });

				productGroup.Products.forEach((product) => {
					products.push({ id: product.id, name: product.name, slug: product.slug, productGroup: productGroup.id });

					let sortedCurrentFutures = product.currentFutures.sortBy('expiresAt');
					let currentFuture = sortedCurrentFutures.find((f) => {
						if (product.slug == 'us-dairy-class-iii' || product.slug == "us-dairy-class-iv" || product.slug == "us-dairy-nonfat-milk" || product.slug == "us-dairy-dry-whey" || product.slug == "us-dairy-cheese" || product.slug == "us-dairy-butter") {
							return moment(f.displayExpiresAt).isAfter(milkCutoffDate);
						}

						return true;
					});

					if (currentFuture) {
						mostCurrentFutures.push({ productName: product.name, slug: product.slug, instrument_id: currentFuture.id });
					}


					currentFutures[product.slug] = product.currentFutures;

					product.currentFutures.forEach((future) => {
						instruments.push({
							id: future.id,
							name: future.name,
							velaInstrumentId: future.velaInstrumentId,
							barchartSymbol: future.barchartSymbol,
							expiresAt: future.expiresAt,
							displayExpiresAt: future.displayExpiresAt,
							exchangeSymbol: future.exchangeSymbol,
							product: product.id,
							productType: future.productType,
							instrumentType: future.instrumentType
						})
					})
				});
			});

			const classThreeProduct = mostCurrentFutures.find((product) => {
				return product.slug == 'us-dairy-class-iii';
			});

			model.classThreeFutureId = classThreeProduct.instrument_id;



			const classFourProduct = mostCurrentFutures.find((product) => {
				return product.slug == "us-dairy-class-iv";
			});

			model.classFourFutureId = classFourProduct.instrument_id;

			const nonfatDryMilkProduct = mostCurrentFutures.find((product) => {
				return product.slug == "us-dairy-nonfat-milk";
			});

			model.nonfatDryMilkFutureId = nonfatDryMilkProduct.instrument_id;

			const dryWheyProduct = mostCurrentFutures.find((product) => {
				return product.slug == "us-dairy-dry-whey";
			});

			model.dryWheyFutureId = dryWheyProduct.instrument_id;

			const cashSettledCheeseProduct = mostCurrentFutures.find((product) => {
				return product.slug == "us-dairy-cheese";
			});

			model.cashSettledCheeseFutureId = cashSettledCheeseProduct.instrument_id;

			const cashSettledButterProduct = mostCurrentFutures.find((product) => {
				return product.slug == "us-dairy-butter";
			});

			model.cashSettledButterFutureId = cashSettledButterProduct.instrument_id;

			const cornProduct = mostCurrentFutures.find((product) => {
				return product.slug == "grain-corn";
			});

			model.cornFutureId = cornProduct.instrument_id;

			const soybeanMealProduct = mostCurrentFutures.find((product) => {
				return product.slug == "grain-soybean-meal";
			});

			model.soybeanMealFutureId = soybeanMealProduct.instrument_id;

			const soybeansProduct = mostCurrentFutures.find((product) => {
				return product.slug == "grain-soybeans";
			});

			model.soybeansFutureId = soybeansProduct.instrument_id;

			const wheatProduct = mostCurrentFutures.find((product) => {
				return product.slug == "grain-chicago-soft-red-winter-wheat";
			});

			model.wheatFutureId = wheatProduct.instrument_id;

			this.store.pushPayload({ 'modern-product-group': productGroups, 'modern-product': products, 'modern-instruments': instruments });

		}

		model.basisInstruments = model.allBasisInstruments.filter(e => e.product.content.globexSymbol === 'BASIS').sortBy("expiration");

		model.endProductBasisInstruments = model.allBasisInstruments.filter(e => e.product.content.globexSymbol === 'UNCONTRACTED_BASIS').sortBy("expiration");

		model.spotInstruments = model.allBasisInstruments.filter(e => e.product.content.globexSymbol === 'NEW_BASIS').sortBy("expiration");

		model.organizations = model.organizations.sortBy("name");

		if (!model.organization || !model.dairy) {
			model.organization = model.organizations.firstObject;

			const initialDairy = model.dairies.find(e => {
				return e.get("organizationId.id") == model.organization.id;
			});

			model.dairy = initialDairy;
		}

		if (model.organization && model.organization.get("showAgropurBasis")) {

			let basisSymbols = model.allBasisInstruments.map(i => {
				return i.symbol;
			});

			model.basisMarketData = this.get("store").query("vault-market-datum", {
				sides: ["Last", "Close"],
				instrument_symbols: basisSymbols
			});

		}

		model.currentUserId = model.user.id;
		model.fullName = `${model.user.firstName} ${model.user.lastName}`;

		let userOrganizationConfig = model.userOrganizationConfigs.find(c => {
			return c.userId.get("id") == model.currentUserId;
		});

		const orgPermissions = userOrganizationConfig.get("permissions");

		model.isOrgAdmin = orgPermissions.includes(300);

		this.get("marketDataPolling").start();

		this.get("marketDataPolling").subscribeTo(model.classThreeFutures);
	},
	setupController(controller, model) {
		this._super(...arguments);

		controller.set("organization_id", model.organization.id);
		controller.set("dairy_id", model.dairy.id);

		// Temporary: Due to issue with server side permissions.
		if (model.dairy) {
			model.manualPositions = this.get("store").query("manual-position", {
				dairy_id: model.dairy.id
			});
		}

		if (
			!model.dairy.isClickwrapAccepted &&
			model.dairy.userId == model.currentUserId
		) {
			controller.set("showClickWrap", true);
		}
	},
	actions: {
		updatePeriod(num, value) {
			this.controller.set(
				"period",
				moment(this.get("context").periodDate)
					.add(num, value)
					.format("YYYYMM")
			);
		},
		updateDisplayMode(mode) {
			this.controller.set("display_mode", mode);
		},
		switchDairy(dairy) {
			this.controller.set("dairy_id", dairy.id);
		},
		toggleInfoModal() {
			this.controller.toggleProperty("showInfoModal");
		},
		showClickWrap() {
			this.controller.set("showClickWrap", true);
		}
	}
});
