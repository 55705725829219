import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if header}}\n  <div class=\"panel-heading\">\n    <div class=\"primary-header\">\n      <div class=\"title\">{{title}}</div>\n    </div>\n  </div>\n{{/if}}\n\n<div class=\"content\" style={{maxHeight}}>\n  <div class=\"card-content primary\">{{yield}}</div>\n</div>\n\n{{#if footer}}\n  <div class=\"panel-footer\">\n    <div class=\"primary-header\">\n      <div class=\"title\">{{title}}</div>\n    </div>\n  </div>\n{{/if}}\n", {"contents":"{{#if header}}\n  <div class=\"panel-heading\">\n    <div class=\"primary-header\">\n      <div class=\"title\">{{title}}</div>\n    </div>\n  </div>\n{{/if}}\n\n<div class=\"content\" style={{maxHeight}}>\n  <div class=\"card-content primary\">{{yield}}</div>\n</div>\n\n{{#if footer}}\n  <div class=\"panel-footer\">\n    <div class=\"primary-header\">\n      <div class=\"title\">{{title}}</div>\n    </div>\n  </div>\n{{/if}}\n","moduleName":"agropur-client/components/vd-panel.hbs","parseOptions":{"srcName":"agropur-client/components/vd-panel.hbs"}});
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({

  classNames : ['panel', 'panel-default', 'vd-panel-with-reveal'],
  title      : null,
  height     : '',

  maxHeight: computed('height', function() {
    const riskyString = `max-height: ${this.height} !important;`;
    return htmlSafe(riskyString);
  })

  // mouseEnter(event) {
  //   console.log('mouseEnter event on vd-panel: ', event);
  // }
});
