import DS from 'ember-data';
const {Model} = DS;
import {alias} from '@ember/object/computed';
import {computed} from '@ember/object';
import vaultDisplayPrice from 'agropur-client/utils/vault-display-price';
import barchartToVelaPrice from 'agropur-client/utils/barchart-to-vela-price';

export default Model.extend({

    name: DS.attr('string'),
    velaInstrumentId: DS.attr('string'),
    barchartSymbol: DS.belongsTo('barchart-market-datum'),
    expiresAt: DS.attr('date'),
    displayExpiresAt: DS.attr('date'),
    exchangeSymbol: DS.attr('string'),
    product: alias('productId'),
    productId: DS.belongsTo('modern-product'),
    symbol: alias('exchangeSymbol'),
    productType: DS.attr('string'), // Future or Option
    instrument_type: alias('instrumentType'),
    instrumentType: DS.attr('string'),
    type: DS.attr('string'),
    option_type: alias('type'),
    strike: DS.attr('string'),
    displayStrike: computed('strike', 'rootSymbol', function () {
        return vaultDisplayPrice(this.strike, this.rootSymbol);
    }),

    // Ideally, this woudl be returned from the server.
    rootSymbol: computed("exchangeSymbol", function () {
        const possibleSymbols = [
            'ZW',
            'CB',
            'GDK',
            'ZS',
            'CSC',
            'LE',
            'ZM',
            'GNF',
            'DC',
            'ZC',
            'DY'
        ];

        if (!this.exchangeSymbol) {
            return null;
        }

        const firstTwo = this.exchangeSymbol.substring(0, 2);

        if (possibleSymbols.includes(firstTwo)) {
            return firstTwo;
        } else {
            const firstThree = this.exchangeSymbol.substring(0, 3);
            return firstThree;
        }

    }),
    lastPrice: alias('barchartSymbol.lastPrice'),
    highPrice: alias('barchartSymbol.high'),
    lowPrice: alias('barchartSymbol.low'),
    displayLastPrice: computed('lastPrice', 'rootSymbol', function () {
        return vaultDisplayPrice(this.lastPrice, this.rootSymbol, 'USD');
    }),
    displayHighPrice: computed('highPrice', 'rootSymbol', function () {
        return vaultDisplayPrice(this.highPrice, this.rootSymbol, 'USD');
    }),
    displayLowPrice: computed('lowPrice', 'rootSymbol', function () {
        return vaultDisplayPrice(this.lowPrice, this.rootSymbol, 'USD');
    }),
    velaFormattedLastPrice: computed('lastPrice', 'rootSymbol', function () {
        return barchartToVelaPrice(this.rootSymbol, this.lastPrice);
    }),

    netChange: computed('barchartSymbol.netChange', 'rootSymbol', function () {
        return vaultDisplayPrice(this.get('barchartSymbol.netChange'), this.rootSymbol);
    }),
    percentChange: alias('barchartSymbol.percentChange')

});
