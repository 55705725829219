import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"row\">\n  <div class=\"col-sm-offset-{{smOffset}} col-sm-{{smColumn}} col-md-offset-{{mdOffset}} col-md-{{mdColumn}} col-lg-offset-{{lgOffset}} col-lg-{{lgColumn}}\">\n    {{yield}}\n  </div>\n</div>\n", {"contents":"<div class=\"row\">\n  <div class=\"col-sm-offset-{{smOffset}} col-sm-{{smColumn}} col-md-offset-{{mdOffset}} col-md-{{mdColumn}} col-lg-offset-{{lgOffset}} col-lg-{{lgColumn}}\">\n    {{yield}}\n  </div>\n</div>\n","moduleName":"agropur-client/components/vd-row-columns.hbs","parseOptions":{"srcName":"agropur-client/components/vd-row-columns.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

  init(){

    this._super(...arguments);
    this.set('column', [12, 12, 12]); // sm, md, lg

    this.set('offset', [0, 0, 0]); // sm, md, lg
  },


  smColumn: computed('column', function() {
    return this.get('column')[0];
  }),

  mdColumn: computed('column', function() {
    return this.get('column')[1];
  }),

  lgColumn: computed('column', function() {
    return this.get('column')[2];
  }),

  smOffset: computed('offset', function() {
    return this.get('offset')[0];
  }),

  mdOffset: computed('offset', function() {
    return this.get('offset')[1];
  }),

  lgOffset: computed('offset', function() {
    return this.get('offset')[2];
  })
});
