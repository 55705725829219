import Controller from "@ember/controller";
import PeriodMixin from "agropur-client/mixins/period";
import CSVExportMixin from "agropur-client/mixins/csv-export";
import moment from "moment";
import $ from "jquery";
import ENV from "agropur-client/config/environment";
import { inject as service } from "@ember/service";
import { computed } from "@ember/object";

export default Controller.extend(PeriodMixin, CSVExportMixin, {
	ENV,
	allDairiesExpanded: false,
	session: service("session"),

	init() {
		this._super(...arguments);
	},

	sortedDairies: computed(
		"model.monthAggregateData.{filteredDairies,filteredDairies.[]}",
		function() {
			return this.model.monthAggregateData.get("filteredDairies").sort((a, b) => {
				if (a.producerId > b.producerId) {
					return 1;
				}

				if (a.producerId < b.producerId) {
					return -1;
				}

				return 0;
			});
		}
	),

	actions: {
		toggleDairy(dairy) {
			dairy.set("expanded", !dairy.expanded);
		},
		expandAllDairies(dairies) {
			this.set("allDairiesExpanded", true);
			dairies.forEach(d => {
				d.set("expanded", true);
			});
		},
		collapseAllDairies(dairies) {
			this.set("allDairiesExpanded", false);
			dairies.forEach(d => {
				d.set("expanded", false);
			});
		},
		updateSelectedDairies(dairies) {
			this.model.selectedDairies.setObjects(dairies);
			this.model.monthAggregateData.get("selectedDairies").setObjects(dairies);
		},
		csvExport() {
			const data = {
				filename: null,
				rows: []
			};

			const header = [
				"Producer Number",
				"Producer",
				"Month",
				"Ticket Number",
				"Product",
				"Bought/Sold",
				"Contracted(lbs)",
				"Type",
				"Strike",
				"Trade Price",
				"Settlement/Actual",
				"Gain/Loss ($/cwt)",
				"Class III Adjustment",
				"Basis Adjustment"
			];
			data.rows.push(header);

			const month = this.get("model.monthAggregateData");

			const date = moment.utc(month.get("date")).format("MMM YYYY");

			month.get("dairies").forEach(d => {
				const positions = d.get("trades");

				if (positions.length > 0) {
					// If you want to see every dairy with positions

					const producerId = d.get("producerId");
					const producer = d.get("name").replace(",", " ");

					let classThree = Number(d.get("classThreeHedgeAdjustment"));
					let basis = Number(d.get("basisHedgeAdjustment"));
					let netHedgeAdjustment = Number(d.get("basisHedgeAdjustment"));
					let poundsHedged = Number(d.get("totalPoundsHedged"));

					for (let i = 0; i < positions.length; i++) {
						const p = positions[i];
						const product = p.get("productId");
						let productFormatted
						if(product === "BASIS" || product === 'NEW_BASIS') {
								productFormatted = "Agropur Basis";
						} else {
								productFormatted = "Class III Milk"
						}
						netHedgeAdjustment = Number(p.get("hedgeAdjustment")).toFixed(2);
						classThree =
							product === "BASIS" || product === "NEW_BASIS" ? "" : Number(netHedgeAdjustment).toFixed(2);

						basis = product === "BASIS" || product === "NEW_BASIS" ? Number(netHedgeAdjustment).toFixed(2) : "";
						poundsHedged = Number(p.get("quantity"));
						const side = p.get("isLong") ? "Bought" : "Sold";
						const type = p.get("type");
						const strike =
							type !== "FORWARD" ? Number(p.get("strike")).toFixed(2) : "";
						const tradePrice = Number(p.get("price")).toFixed(2);
						const productPrice = Number(p.get("currentPrice")).toFixed(2);
						const hedgePL = Number(p.get("pl")).toFixed(2);
						const ticketNumber = p.get("instrumentId");

						const positionRow = [
							producerId,
							producer,
							date,
							ticketNumber,
							productFormatted,
							side,
							poundsHedged,
							type,
							strike,
							tradePrice,
							productPrice,
							hedgePL,
							classThree,
							basis
						];
						data.rows.push(positionRow);
					}
				}
			});

			data.filename = this.get("model.organization.name")
				.split(" ")
				.join("_");

			return this.downloadCSVFile(
				data,
				"Class III & Basis P/L",
				this.model.periodDate
			);
		},

		exportToFTP(month, organization) {
			const data = {
				filename: null,
				rows: []
			};

			const division = organization.get("division");
			const date = moment.utc(month.get("date"));
			const m = date.format("M");
			const y = date.format("YY");
			const updatedAt = moment.utc();

			month.get("dairies").forEach(d => {
				const classThree = d.get("classThreeHedgeAdjustment");
				const basis = d.get("basisHedgeAdjustment");
				const positions = d.get("trades");

				if (positions.length > 0) {
					// If you want to see every dairy with positions
					const row = [
						division,
						d.get("producerId"),
						m,
						y,
						classThree.toFixed(2),
						basis.toFixed(2)
					];
					data.rows.push(row);
				}
			});

			data.filename = `${updatedAt.format("YYYYMMDD_HHmmss")}_${division
				.split(" - ")[1]
				.toUpperCase()}_${date.format("MMYYYY")}`;

			let csvCon = "";
			data.rows.forEach(rowArray => {
				const row = rowArray.join(",");
				csvCon += `${row}\r\n`;
			});
			const csvContent = new Blob([csvCon], { type: "multipart/form-data" });

			// eslint-disable-next-line no-undef
			const n = new Noty({
				text: "Are you sure you want to export to DSI?",
				layout: "center",
				animation: {
					open: null,
					close: null
				},
				modal: true,
				force: true,
				closeWith: ["button"],
				timeout: false,
				buttons: [
					// eslint-disable-next-line no-undef
					Noty.button("Send", "btn btn-danger btn-sm", () => {
						const filename = `${division.split(" - ")[1].toUpperCase()}_${moment(
							this.get("organization.displayMonth")
						)
							.utc()
							.format("MMYYYY")}.csv`;
						const formData = new FormData();
						formData.append("upload", csvContent, filename);

						return $.ajax({
							url: `${ENV.APP.HOST}/commercial_api/api/exports/agropur/dsi`,
							type: "POST",
							headers: {
								'Authorization': `Bearer ${this.get('session.data.authenticated.access_token')}`
							},
							context: this,
							processData: false,
							contentType: false,
							data: formData
						})
							.done(function() {
								// eslint-disable-next-line no-undef
								new Noty({
									text: `${filename} has beed sucessfully exported`,
									type: "success"
								}).show();
								n.close();
							})
							.fail(function(jqXHR, textStatus, errorThrown) {
								if (jqXHR.status === 401) {
									// eslint-disable-next-line no-undef
									new Noty({
										text: `${errorThrown}`,
										type: "error"
									}).show();
									n.close();
								}
							});
					}),
					// eslint-disable-next-line no-undef
					Noty.button("Cancel", "btn btn-default btn-sm", () => {
						n.close();
					})
				]
			}).show();
		}
	}
});
