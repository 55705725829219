import DS from "ember-data";

export default DS.Model.extend({
	close: DS.attr("number"),
	dayCode: DS.attr("string"),
	dollarVolume: DS.attr("number"),
	flag: DS.attr("string"),
	high: DS.attr("number"),
	lastPrice: DS.attr("number"),
	low: DS.attr("number"),
	mode: DS.attr("string"),
	name: DS.attr("string"),
	netChange: DS.attr("number"),
	numTrades: DS.attr("number"),
	open: DS.attr("number"),
	percentChange: DS.attr("number"),
	previousVolume: DS.attr("number"),
	serverTimestamp: DS.attr("date"),
	symbol: DS.attr("string"),
	tradeTimestamp: DS.attr("date"),
	unitCode: DS.attr("string"),
	volume: DS.attr("number"),
	instrument: DS.belongsTo("modern-instrument"),
	openInterest: DS.attr("number"),
	settlement: DS.attr("number"),
	previousSettlement: DS.attr("number")
});
