import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import Route from "@ember/routing/route";
import { hash } from "rsvp";
import moment from "moment";

export default Route.extend(AuthenticatedRouteMixin, {
	model() {
		const application = this.modelFor("authenticated");

		const {
			displayMode,
			dairy,
			organization,
			basisInstruments
		} = application;

		// Show the previous month for the first week of the new month.
		let startDate =
			moment().date() < 8
				? moment()
					.startOf("month")
					.subtract(1, "month")
				: moment().startOf("month");

		const instruments = this.store.peekAll('modern-instrument');

		return hash({
			displayMode,
			organization,
			months: this.get("store").query("month", {
				start_date: startDate.format("YYYY-MM-DD"),
				end_date: moment(startDate)
					.add(23, "months")
					.format("YYYY-MM-DD"),
				dairy_id: dairy.id
			}),
			classThreeFuture: this.store.peekRecord('modern-instrument', application.classThreeFutureId),
			classFourFuture: this.store.peekRecord('modern-instrument', application.classFourFutureId),
			dryWheyFuture: this.store.peekRecord('modern-instrument', application.dryWheyFutureId),
			cashSettledCheeseFuture: this.store.peekRecord('modern-instrument', application.cashSettledCheeseFutureId),
			nonfatDryMilkFuture: this.store.peekRecord('modern-instrument', application.nonfatDryMilkFutureId),
			cashSettledButterFuture: this.store.peekRecord('modern-instrument', application.cashSettledButterFutureId),
			soybeanMealFuture: this.store.peekRecord('modern-instrument', application.soybeanMealFutureId),
			cornFuture: this.store.peekRecord('modern-instrument', application.cornFutureId),
			soybeanFuture: this.store.peekRecord('modern-instrument', application.soybeansFutureId),
			wheatFuture: this.store.peekRecord('modern-instrument', application.wheatFutureId),
			classThreeFutures: instruments.filter((i) => {
				return i.name.includes("Class III") && i.productType == 'Future';
			}).sortBy('displayExpiresAt'),
			basisInstruments
		});
	},
	afterModel(model) {
		model.months = model.months.sortBy("date");

		let month;
		let instrument;
		let basisInstrument;
		for (let i = 0; i < model.months.length; i++) {
			month = model.months[i];
			instrument = model.classThreeFutures.find(e => {
				return moment(e.get("displayExpiresAt")).isSame(month.date, "day");
			});
			month.set("classThreeMilkInstrument", instrument);

			basisInstrument = model.basisInstruments.find(e => {
				return moment.utc(e.get("expiration")).isSame(month.date, "month");
			});
			month.set("agropurBasisInstrument", basisInstrument);
		}
	}
});
