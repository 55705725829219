import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FaIcon @icon=\"info-circle\" />\n{{#if showTooltip}}\n<div id=\"{{elementId}}-tooltip\" class=\"tooltip fade bottom in\" role=\"tooltip\"\n  style=\"display:block; visibility: hidden;\">\n  <div class=\"tooltip-arrow\"></div>\n  <div class=\"tooltip-inner\">{{yield}}{{_positionLeft}}</div>\n</div>\n{{/if}}", {"contents":"<FaIcon @icon=\"info-circle\" />\n{{#if showTooltip}}\n<div id=\"{{elementId}}-tooltip\" class=\"tooltip fade bottom in\" role=\"tooltip\"\n  style=\"display:block; visibility: hidden;\">\n  <div class=\"tooltip-arrow\"></div>\n  <div class=\"tooltip-inner\">{{yield}}{{_positionLeft}}</div>\n</div>\n{{/if}}","moduleName":"agropur-client/components/vd-tooltip-icon.hbs","parseOptions":{"srcName":"agropur-client/components/vd-tooltip-icon.hbs"}});
import Component from '@ember/component';
import { later } from '@ember/runloop';

export default Component.extend({
  tagName: 'em',

  attributeBindings: [
    'data-toggle',
    'data-placement',
    'title'
  ],

  classNames  : ['vd-tooltip-icon'],
  showTooltip : false,
  showIcon    : false,

  'data-toggle': 'tooltip',

  mouseEnter() {
    const iconWidth = this.$().width() / 2;
    this.set('showTooltip', true);
    later(() => this._updateTooltipPosition(iconWidth));
  },

  _updateTooltipPosition(iconWidth) {
    const el = this.$().find(`#${this.elementId}-tooltip`);
    const position = ((el.width() / 2) * -1) + iconWidth;
    el.css('left', `${position}px`);
    el.css('visibility', 'visible');
  },

  mouseLeave() {
    this.set('showTooltip', false);
  },

  init(...args) {
    this._super(...args);
  }
});
