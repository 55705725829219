import Controller from "@ember/controller";
import ENV from "agropur-client/config/environment";
import { computed } from "@ember/object";
import moment from "moment";
import $ from "jquery";
import { inject as service } from '@ember/service';

export default Controller.extend({
	queryParams: ["dairy_id", "organization_id", "period", "display_mode"],
	brandLogoUrl: null,
	showInfoModal: false,
	showClickWrap: false,
	session       : service(),
	ENV,

	init() {
		this._super(...arguments);
		this.set("brandLogoUrl", ENV.BRAND.menuLogoPath);
		this.set("period", moment().format("YYYYMM"));
		this.set("display_mode", "cwt");
	},

	showTools: computed(
		"model.{isOrgAdmin,organization.showAgropurBasis}",
		function() {
			return this.model.isOrgAdmin && this.model.organization.showAgropurBasis;
		}
	),
	organizationDairies: computed(
		"model.dairies.[]",
		"organization_id",
		function() {
			return this.model.dairies
				.filter(d => {
					return d.get("organizationId.id") == this.get("organization_id");
				})
				.sortBy("name");
		}
	),

	actions: {
		switchOrganization(organization) {
			this.set("organization_id", organization.id);
			const initialDairy = this.model.dairies.find(e => {
				return e.get("organizationId.id") == organization.id;
			});
			this.set("dairy_id", initialDairy.id);
		},
		switchDairy(dairy) {
			this.set("dairy_id", dairy.id);
		},
		acceptClickwrapAgreement(dairy) {
			dairy.save();
			this.toggleProperty("showClickWrap");
		},
		showSideMenu() {
			$("#side-menu").show();
			$("#side-menu").scrollTop(0);
		},

		hideSideMenu() {
			$("#side-menu").hide();
		}
	}
});
