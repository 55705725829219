import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import Route from "@ember/routing/route";
import ENV from "agropur-client/config/environment";
import { hash } from "rsvp";
import EmberObject from "@ember/object";
import { A } from "@ember/array";
import { computed } from "@ember/object";
import moment from "moment";

const AggregationTrade = EmberObject.extend({
	deleted: null,
	id: null,
	isLong: null,
	tradeType: null,
	price: null,
	productId: null,
	quantity: null,
	strike: null,
	timestamp: null,
	type: null,
	userId: null,
	month: null,
	instrumentId: null,
	broker: null,

	// eslint-disable-next-line ember/use-brace-expansion
	currentPrice: computed(
		"dairy.month.classThreePrice",
		"dairy.month.basisInstrument.price",
		"dairy.month.spotInstrument.price",
		"dairy.month.spotInstrument.lastMarketDatum",
		"productId",
		function () {
			let month = this.dairy.month;
			let basisPrice = month.basisInstrument ? month.basisInstrument.price : 0;
			let spotPrice = month.spotInstrument ? month.spotInstrument.price : 0;
			let classThreePrice = month.classThreePrice ? month.classThreePrice : 0;

			let outrightPrice = null;

			const isSpotPriceSet = month.spotInstrument?.lastMarketDatum != null;

			outrightPrice =
				this.productId === "BASIS"
					? isSpotPriceSet
						? spotPrice
						: basisPrice
					: this.productId === "DC"
					? classThreePrice
					: null;

			switch (this.type) {
				case "CALL":
					return this.strike < outrightPrice ? outrightPrice - this.strike : 0;
				case "PUT":
					return this.strike > outrightPrice ? this.strike - outrightPrice : 0;
				default:
					return outrightPrice;
			}
		}
	),

	pl: computed("price", "currentPrice", "isLong", function () {
		return this.isLong
			? this.currentPrice - this.price
			: this.price - this.currentPrice;
	}),

	hedgeAdjustment: computed("pl", "quantity", function () {
		return (this.quantity / 100) * this.pl;
	}),

	init(p) {
		this._super(...arguments);
		this.tradeType = p.positionType;
		this.isLong = p.long;
	},
});

const AggregationDairy = EmberObject.extend({
	id: null,
	producerId: null,
	name: null,
	trades: null,
	date: null,
	plannedProduction: null,
	actualProduction: null,
	productionDifference: null,
	productionPercentageDifference: null,
	dairy: null,

	classThreeTrades: computed("trades.@each.productId", function () {
		return this.trades.filter((t) => {
			return t.productId == "DC";
		});
	}),

	classThreePoundsHedged: computed("classThreeTrades.@each.type", function () {
		let putPounds = 0;
		let callPounds = 0;
		let forwardPounds = 0;

		this.classThreeTrades.forEach((t) => {
			if (t.type == "FORWARD") {
				forwardPounds += t.quantity;
			}
			if (t.type == "PUT") {
				putPounds += t.quantity;
			}
			if (t.type == "CALL") {
				callPounds += t.quantity;
			}
		});

		return forwardPounds + (putPounds > callPounds ? putPounds : callPounds);
	}),

	classThreePercentageHedged: computed(
		"classThreePoundsHedged",
		"plannedProduction",
		function () {
			return this.plannedProduction
				? this.classThreePoundsHedged / this.plannedProduction
				: 0;
		}
	),

	basisTrades: computed("trades.@each.productId", function () {
		return this.trades.filter((t) => {
			return t.productId == "NEW_BASIS" || t.productId === "BASIS";
		});
	}),

	basisPoundsHedged: computed("basisTrades.@each.quantity", function () {
		let pounds = 0;

		this.basisTrades.forEach((t) => {
			pounds += t.quantity;
		});

		return pounds;
	}),

	basisPercentageHedged: computed(
		"basisPoundsHedged",
		"plannedProduction",
		function () {
			return this.plannedProduction
				? this.basisPoundsHedged / this.plannedProduction
				: 0;
		}
	),

	totalPoundsHedged: computed(
		"classThreePoundsHedged",
		"basisPoundsHedged",
		function () {
			return this.classThreePoundsHedged + this.basisPoundsHedged;
		}
	),

	averageBasisPrice: computed(
		"basisPoundsHedged",
		"basisTrades.@each.{price,quantity}",
		function () {
			let weight;
			let price = 0;
			this.basisTrades.forEach((t) => {
				weight = t.quantity / this.basisPoundsHedged;
				price += weight * t.price;
			});

			return price;
		}
	),

	weightedAvgPrice: computed(
		"basisPoundsHedged",
		"averageBasisPrice",
		"plannedProduction",
		"month.basisInstrument.price",
		function () {
			let poundsNotHedged = this.plannedProduction - this.basisPoundsHedged;
			return (
				(this.averageBasisPrice * this.basisPoundsHedged +
					this.month.basisInstrument.price * poundsNotHedged) /
				this.plannedProduction
			);
		}
	),

	actualWeightedAvgPrice: computed(
		"basisPoundsHedged",
		"averageBasisPrice",
		"actualProduction",
		"month.basisInstrument.price",
		function () {
			if (this.actualProduction) {
				let poundsNotHedged = this.actualProduction - this.basisPoundsHedged;
				return (
					(this.averageBasisPrice * this.basisPoundsHedged +
						this.month.basisInstrument.price * poundsNotHedged) /
					this.actualProduction
				);
			} else {
				return null;
			}
		}
	),

	classThreeHedgeAdjustment: computed(
		"classThreeTrades.@each.hedgeAdjustment",
		function () {
			return this.classThreeTrades.reduce((acc, cur) => {
				return acc + cur.hedgeAdjustment;
			}, 0);
		}
	),

	basisHedgeAdjustment: computed(
		"basisTrades.@each.hedgeAdjustment",
		function () {
			return this.basisTrades.reduce((acc, cur) => {
				return acc + cur.hedgeAdjustment;
			}, 0);
		}
	),

	netHedgeAdjustment: computed(
		"classThreeHedgeAdjustment",
		"basisHedgeAdjustment",
		function () {
			return this.classThreeHedgeAdjustment + this.basisHedgeAdjustment;
		}
	),

	init(d) {
		this._super(...arguments);

		this.id = d.dairyId;
		this.producerId = d.producerId;
		this.name = d.dairyName;
		this.date = d.date;

		this.plannedProduction = d.production.totalProduction;
		this.actualProduction = d.production.actualProduction;
		this.productionDifference =
			d.production.actualProduction - d.production.totalProduction;
		this.productionPercentageDifference =
			this.productionDifference / this.plannedProduction;

		this.trades = A();
		let trade;
		d.positions.forEach((p) => {
			p.producerId = this.producerId;
			p.producerName = this.name;
			p.expiration = this.date;
			trade = AggregationTrade.create(p);
			trade.set("dairy", this);
			this.trades.push(trade);
		});
	},
});

const MonthAggregation = EmberObject.extend({
	dairies: null,
	selectedDairies: null,
	date: null,
	basisInstrument: null,
	spotInstrument: null,
	classThreeFuture: null,
	historicalPrices: null,

	filteredDairies: computed(
		"dairies",
		"dairies.[]",
		"selectedDairies",
		"selectedDairies.[]",
		function () {
			if (this.selectedDairies && this.selectedDairies.length > 0) {
				let dairyIds = this.selectedDairies.mapBy("id");

				return this.get("dairies").filter((d) => {
					return dairyIds.find((f) => {
						return f == d.id;
					});
				});
			} else {
				return this.get("dairies");
			}
		}
	),

	classThreePrice: computed(
		"classThreeFuture",
		"classThreeFuture.lastPrice",
		"historicalPrices.classThree",
		function () {
			let historicalPrice =
				this.historicalPrices && this.historicalPrices.classThree
					? this.historicalPrices.classThree
					: null;
			let futurePrice =
				this.classThreeFuture && this.classThreeFuture.lastPrice
					? this.classThreeFuture.lastPrice
					: null;
			return historicalPrice ? historicalPrice : futurePrice;
		}
	),

	plannedProduction: computed(
		"filteredDairies.@each.plannedProduction",
		function () {
			return this.filteredDairies.reduce((acc, cur) => {
				return acc + cur.plannedProduction;
			}, 0);
		}
	),
	actualProduction: computed(
		"filteredDairies.@each.actualProduction",
		function () {
			return this.filteredDairies.reduce((acc, cur) => {
				return acc + cur.actualProduction;
			}, 0);
		}
	),
	productionDifference: computed(
		"plannedProduction",
		"actualProduction",
		function () {
			return this.actualProduction - this.plannedProduction;
		}
	),
	productionPercentageDifference: computed(
		"plannedProduction",
		"productionDifference",
		function () {
			return this.plannedProduction
				? this.productionDifference / this.plannedProduction
				: 0;
		}
	),
	trades: computed("filteredDairies.@each.trades", function () {
		return this.filteredDairies.flatMap((d) => {
			return d.trades;
		});
	}),
	classThreePoundsHedged: computed("filteredDairies.@", function () {
		return this.filteredDairies.reduce((arr, cur) => {
			return arr + cur.classThreePoundsHedged;
		}, 0);
	}),
	classThreePercentageHedged: computed(
		"classThreePoundsHedged",
		"plannedProduction",
		function () {
			return this.plannedProduction
				? this.classThreePoundsHedged / this.plannedProduction
				: 0;
		}
	),
	basisPoundsHedged: computed("filteredDairies.@", function () {
		return this.filteredDairies.reduce((arr, cur) => {
			return arr + cur.basisPoundsHedged;
		}, 0);
	}),
	basisPercentageHedged: computed(
		"basisPoundsHedged",
		"plannedProduction",
		function () {
			return this.basisPoundsHedged / this.plannedProduction;
		}
	),
	averageBasisPrice: computed(
		"basisPoundsHedged",
		"filteredDairies.@each.{averageBasisPrice,basisPoundsHedged}",
		function () {
			let weight;
			let price = 0;
			this.filteredDairies.forEach((dairy) => {
				weight = dairy.basisPoundsHedged / this.basisPoundsHedged;
				price += weight * dairy.averageBasisPrice;
			});

			return price;
		}
	),

	weightedAvgPrice: computed(
		"basisPoundsHedged",
		"averageBasisPrice",
		"plannedProduction",
		"basisInstrument.price",
		function () {
			let poundsNotHedged = this.plannedProduction - this.basisPoundsHedged;
			let basisPrice = this.basisInstrument ? this.basisInstrument.price : 0;
			return (
				(this.averageBasisPrice * this.basisPoundsHedged +
					basisPrice * poundsNotHedged) /
				this.plannedProduction
			);
		}
	),

	actualWeightedAvgPrice: computed(
		"basisPoundsHedged",
		"averageBasisPrice",
		"actualProduction",
		"basisInstrument.price",
		function () {
			if (this.actualProduction) {
				let poundsNotHedged = this.actualProduction - this.basisPoundsHedged;
				let basisPrice = this.basisInstrument ? this.basisInstrument.price : 0;
				return (
					(this.averageBasisPrice * this.basisPoundsHedged +
						basisPrice * poundsNotHedged) /
					this.actualProduction
				);
			} else {
				return null;
			}
		}
	),

	classThreeHedgeAdjustment: computed(
		"filteredDairies.@each.classThreeHedgeAdjustment",
		function () {
			return this.filteredDairies.reduce((acc, cur) => {
				return acc + cur.classThreeHedgeAdjustment;
			}, 0);
		}
	),

	basisHedgeAdjustment: computed(
		"filteredDairies.@each.basisHedgeAdjustment",
		function () {
			return this.filteredDairies.reduce((acc, cur) => {
				return acc + cur.basisHedgeAdjustment;
			}, 0);
		}
	),

	netHedgeAdjustment: computed(
		"filteredDairies.@each.netHedgeAdjustment",
		function () {
			return this.filteredDairies.reduce((acc, cur) => {
				return acc + cur.netHedgeAdjustment;
			}, 0);
		}
	),

	init(m) {
		this._super(...arguments);
		this.date = m.date;

		this.dairies = A();
		this.selectedDairies = A();

		let sortedDairies = m.dairies.sort((a, b) => {
			if (a.producerId > b.producerId) {
				return 1;
			}
			if (a.producerId < b.producerId) {
				return -1;
			}
			return 0;
		});

		let dairy;
		sortedDairies.forEach((d) => {
			d.date = this.date;
			dairy = AggregationDairy.create(d);
			dairy.set("month", this);
			this.dairies.push(dairy);
		});
	},
});

export default Route.extend(AuthenticatedRouteMixin, {
	model() {
		let { organization, basisInstruments, historicalPrices, spotInstruments } =
			this.modelFor("authenticated");
		return hash({
			organization,
			basisInstruments,
			historicalPrices,
			spotInstruments,
			classThreeFutures: this.store
				.peekAll("modern-instrument")
				.filter((i) => {
					return i.name.includes("Class III") && i.productType == "Future";
				})
				.sortBy("displayExpiresAt"),
			initialAggregateData: fetch(
				`${ENV.APP.HOST}/commercial_api/api/aggregation/organization/${organization.id}`,
				{
					headers: {
						Authorization: `Bearer ${this.get(
							"session.data.authenticated.access_token"
						)}`,
					},
				}
			).then(function (response) {
				return response.json();
			}),
		});
	},
	afterModel(model) {
		let arr = A();
		let ins;
		let month;

		model.initialAggregateData.forEach((m) => {
			delete m.actualProduction;
			month = MonthAggregation.create(m);

			ins = model.basisInstruments.find((e) => {
				return e && m && e.date == m.date;
			});

			month.set("basisInstrument", ins);

			ins = model.spotInstruments.find((e) => {
				return e && m && e.date == m.date;
			});

			month.set("spotInstrument", ins);

			ins = model.classThreeFutures.find((e) => {
				return moment(e.get("displayExpiresAt")).utc().isSame(m.date, "day");
			});

			month.set("classThreeFuture", ins);

			ins = model.historicalPrices.find((e) => {
				return e && m && e.date == m.date;
			});

			month.set("historicalPrices", ins);

			arr.push(month);
		});

		model.aggregateData = arr;
	},
});
