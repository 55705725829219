import DS from 'ember-data';
import ENV from 'agropur-client/config/environment';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import {isPresent} from '@ember/utils';

export default DS.JSONAPIAdapter.extend(DataAdapterMixin, {
  host      : ENV.APP.HOST,
  namespace : 'commercial_api/api',

  authorize(xhr) {
    let {access_token} = this.get('session.data.authenticated');
    if (isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    }
  }
});
