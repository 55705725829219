import { computed } from "@ember/object";
import moment from "moment";
import Controller from "@ember/controller";
import DisplayModeMixin from "agropur-client/mixins/display-mode";
import ENV from "agropur-client/config/environment";

export default Controller.extend(DisplayModeMixin, {
	ENV,
	chartPriceFormattiong: computed("model.displayMode", function() {
		return this.get("model.displayMode") == "cwt" ? "$0.00" : "$0,0";
	}),

	chartData: computed(
		"model.{months.@each.classThreeMilkPrice,displayMode}",
		"application.organization.division",
		function() {
			let months = this.model.months.filter(month => {
				return (
					moment.utc(month.get("date")).isSameOrBefore(moment().add(12, "months")) ||
					month.get("agropurBasisPrice") !== 0
				);
			});

			let config = { columns: [], names: {} };

			config["columns"].push(
				["x"].concat(
					months.map(month => {
						return moment.utc(month.get("date")).format("YYYY-MM-DD");
					})
				)
			);

			config["columns"].push(
				["data1"].concat(
					months.map(month => {
						if (this.get("model.displayMode") == "cwt") {
							return month.get("classThreeMilkPrice");
						} else {
							return (
								month.get("classThreeMilkPrice") * month.get("totalProductionCwt")
							);
						}
					})
				)
			);

			config["names"]["data1"] = "Class III Milk Price";

			if (this.get("model.organization.showAgropurBasis")) {
				config["columns"].push(
					["data2"].concat(
						months.map(month => {
							if (this.get("model.displayMode") == "cwt") {
								return month.get("agropurForwardPrice");
							} else {
								return (
									month.get("agropurForwardPrice") * month.get("totalProductionCwt")
								);
							}
						})
					)
				);

				config["names"]["data2"] = "Total Forward Price";
			}

			return config;
		}
	)

});
