import Route from '@ember/routing/route';
import OAuth2ImplicitGrantCallbackRouteMixin from 'ember-simple-auth/mixins/oauth2-implicit-grant-callback-route-mixin';

export default Route.extend(OAuth2ImplicitGrantCallbackRouteMixin, {
    authenticator: 'authenticator:oauth2-implicit-grant',
    actions: {
        didTransition() {
            // If Ember-Simple-Auth interprets the request as the user
            // wanting to go to this route, redirect the user.
            //
            // This happens when the user is already logged in, but is given a link
            // to the OAuth flow
            this.transitionTo('/');

            // This is required due to the application structure (i.e. getting data at the application level)
            // This forces a reload, which allows all of the data to load correctly.
            // window.location.replace("/");
        }
      }
  });
