import DS from "ember-data";
import ENV from "agropur-client/config/environment";

export default DS.RESTAdapter.extend({
	host: ENV.APP.BARCHART_HOST,
	coalesceFindRequests: true,
	findRecord: function(store, type, value) {
		return this.ajax(`${this.host}/getQuote.json`, "GET", {
			data: {
				apikey: ENV.APP.BARCHART_API_KEY,
				symbols: value,
				fields: "previousSettlement,settlement,openInterest"
			}
		});
	},

	findMany: function(store, type, ids) {
		return this.ajax(`${this.host}/getQuote.json`, "GET", {
			data: {
				apikey: ENV.APP.BARCHART_API_KEY,
				symbols: ids.join(","),
				fields: "previousSettlement,settlement,openInterest"
			}
		});
	}
});
