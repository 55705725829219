import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if show}}\n<td colspan=\"{{colspan}}\" class=\"text-center\">\n  {{#if showIcon}}\n    <div class=\"progress\">\n      <div class=\"indeterminate\"><i class=\"fa fa-spinner fa-spin fa-fw\"></i><span class=\"sr-only\">Loading...</span></div>\n    </div>\n  {{else}}\n    <i class=\"fa fa-ellipsis-h\" aria-hidden=\"true\"></i>\n  {{/if}}\n</td>\n{{/if}}\n", {"contents":"{{#if show}}\n<td colspan=\"{{colspan}}\" class=\"text-center\">\n  {{#if showIcon}}\n    <div class=\"progress\">\n      <div class=\"indeterminate\"><i class=\"fa fa-spinner fa-spin fa-fw\"></i><span class=\"sr-only\">Loading...</span></div>\n    </div>\n  {{else}}\n    <i class=\"fa fa-ellipsis-h\" aria-hidden=\"true\"></i>\n  {{/if}}\n</td>\n{{/if}}\n","moduleName":"agropur-client/components/vd-table-loading-row.hbs","parseOptions":{"srcName":"agropur-client/components/vd-table-loading-row.hbs"}});
import Component from '@ember/component';

export default Component.extend({
  tagName  : 'tr',
  colspan  : 1,
  show     : false,
  showIcon : true
});
