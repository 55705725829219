import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if mapConfig}}\n  {{#leaflet-map lat=mapConfig.lat lng=mapConfig.lng zoom=mapConfig.zoom}}\n    {{tile-layer url=\"http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"}}\n    {{#marker-layer location=mapConfig.dairyLocation}}\n      {{#popup-layer}}\n        <p>{{yield}}</p>\n      {{/popup-layer}}\n    {{/marker-layer}}\n  {{/leaflet-map}}\n{{else}}\nLoading map...\n{{/if}}\n", {"contents":"{{#if mapConfig}}\n  {{#leaflet-map lat=mapConfig.lat lng=mapConfig.lng zoom=mapConfig.zoom}}\n    {{tile-layer url=\"http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"}}\n    {{#marker-layer location=mapConfig.dairyLocation}}\n      {{#popup-layer}}\n        <p>{{yield}}</p>\n      {{/popup-layer}}\n    {{/marker-layer}}\n  {{/leaflet-map}}\n{{else}}\nLoading map...\n{{/if}}\n","moduleName":"agropur-client/components/vd-ember-leaflet-map.hbs","parseOptions":{"srcName":"agropur-client/components/vd-ember-leaflet-map.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';
import $ from 'jquery';
import { debounce } from '@ember/runloop';
import EmberObject from '@ember/object';

export default Component.extend({
  // externalData: service(),

  defaultLocation: 'Chicago,+Illinois',

  location  : null,
  mapConfig : null,

  street : null,
  city   : null,
  state  : null,

  didReceiveAttrs() {
    debounce(this, this._loadLocationMap, 1000);
  },

  didUpdateAttrs() {
    debounce(this, this._loadLocationMap, 1000);
  },

  _loadLocationMap() {
    const query = (this.get('street') ? `${this.get('street')},+${this.get('city')},+${this.get('state')}` : `${this.get('city')},+${this.get('state')}`) || this.get('defaultLocation');
    this._loadMap(query);
  },

  getMapInfo(query) {

    return new Promise((resolve) => {

      const google = 'AIzaSyD3ZHCRBnVwHcyv3DHUMzkkireXzgz8THY';
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${google}`;

      let data = $.ajax({
        url,
        success: (resp) => {
          /* eslint-disable no-param-reassign */
          // resp.datetime = moment().utc();
          resp.query = query;
          resp.coords = resp.results[0].geometry.location;
          // resp.text = resp.results[0].formatted_address;
          /* eslint-enable no-param-reassign */

        }
      });

      resolve(data);
    })
  },

  _loadMap(query) {
    this.getMapInfo(query)
      .then((info) => {
        this.set('mapConfig', this._setMapConfig(info.coords));
      });
  },

  _setMapConfig(coords) {
    const Config = EmberObject.extend({
      zoom : 12,
      // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
      lat  : coords.lat || 41.8797853,
      // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
      lng  : coords.lng || -87.6339769,

      dairyLocation: computed('lat', 'lng', function() {
        return [coords.lat, coords.lng];
      })
    });
    return Config.create({ coords });
  }
});
