import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasChange}}\n    {{#if this.hasPositiveChange}}\n        <span style='color: green;' title=\"Session Change\">{{ formattedNetChange }}\n            ({{ format-number percentChange \"0,0.00\" }}%)\n            <i aria-hidden=\"true\" class=\"fa fa-arrow-up\"></i>\n        </span>\n    {{ else }}\n        <span style='color: red;' title=\"Session Change\">{{ formattedNetChange }}\n            ({{ format-number percentChange \"0,0.00\" }}%)\n            <i aria-hidden=\"true\" class=\"fa fa-arrow-down\"></i>\n        </span>\n    {{/if}}\n{{ else }}\n    -\n{{/if}}\n", {"contents":"{{#if this.hasChange}}\n    {{#if this.hasPositiveChange}}\n        <span style='color: green;' title=\"Session Change\">{{ formattedNetChange }}\n            ({{ format-number percentChange \"0,0.00\" }}%)\n            <i aria-hidden=\"true\" class=\"fa fa-arrow-up\"></i>\n        </span>\n    {{ else }}\n        <span style='color: red;' title=\"Session Change\">{{ formattedNetChange }}\n            ({{ format-number percentChange \"0,0.00\" }}%)\n            <i aria-hidden=\"true\" class=\"fa fa-arrow-down\"></i>\n        </span>\n    {{/if}}\n{{ else }}\n    -\n{{/if}}\n","moduleName":"agropur-client/components/vault-session-change.hbs","parseOptions":{"srcName":"agropur-client/components/vault-session-change.hbs"}});
import Component from '@ember/component';
import {computed} from '@ember/object';

export default Component.extend({
    netChange: null, percentChange: null, fractionalDecimals: null,

    // This is only needed when we aren't getting the price from the formatted instrument prices.
    // Currently, this is needed for spot prices.
    formattedNetChange: computed('netChange', function () {
        if (this.fractionalDecimals) {
            return Intl.NumberFormat('en-US', {
                minimumFractionDigits: this.fractionalDecimals,
                maximumFractionDigits: this.fractionalDecimals
            }).format(this.netChange);
        } else {
            return this.netChange;
        }
    }),

    hasChange: computed('netChange', function () {
        return this.netChange > 0 || this.netChange < 0;
    }),

    hasPositiveChange: computed('netChange', function () {
        return this.netChange > 0;
    })

});
