import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#power-select\n  options=searchPromise\n  allowClear=false\n  matcher=searchMatcher\n  renderInPlace=true\n  oninput=(action 'doSearch')\n  loadingMessage=\"Waiting for the server....\"\n  placeholder=milk-check-calculator.edit-location-placeholder\n\n  selected=dairy.locationId\n  onchange=(action 'updateDairyLocation')\n  onclose=(action 'closeSearch') as |location|}}\n{{!-- {{highlight-substr (concat location.city ', ' location.county ', ' location.state) searchterm}} --}}\n{{else}}\n<div class=\"text-center\">\n  <!-- <img width=\"150\" src=\"http://www.ember-power-select.com/grumpy-cat-5b86cf02061cc6f8018292193bcf0880.png\"> -->\n</div>\n{{/power-select}}", {"contents":"{{#power-select\n  options=searchPromise\n  allowClear=false\n  matcher=searchMatcher\n  renderInPlace=true\n  oninput=(action 'doSearch')\n  loadingMessage=\"Waiting for the server....\"\n  placeholder=milk-check-calculator.edit-location-placeholder\n\n  selected=dairy.locationId\n  onchange=(action 'updateDairyLocation')\n  onclose=(action 'closeSearch') as |location|}}\n{{!-- {{highlight-substr (concat location.city ', ' location.county ', ' location.state) searchterm}} --}}\n{{else}}\n<div class=\"text-center\">\n  <!-- <img width=\"150\" src=\"http://www.ember-power-select.com/grumpy-cat-5b86cf02061cc6f8018292193bcf0880.png\"> -->\n</div>\n{{/power-select}}","moduleName":"agropur-client/components/vd-select-location.hbs","parseOptions":{"srcName":"agropur-client/components/vd-select-location.hbs"}});
import Component from '@ember/component';
import { debounce } from '@ember/runloop';

export default Component.extend({

  dairy: null,

  searchTerm    : null,
  searchPromise : null,

  _serachLocations() {
    this.set('searchPromise',
      this.get('application')
        .searchLocation(this.get('searchterm'))
        .then(this._groupResults)
    );
  },

  _groupResults(list) {
    // This array will contain the reference to map group objects
    const result = [];

    // Group the list by 'state' attr\
    /* eslint-disable no-param-reassign */
    list.reduce((map, loc) => {
      if (!map[loc.state]) {
        result.push(map[loc.state] = {
          groupName : loc.state,
          options   : [loc]
        });
      } else {
        map[loc.state].options.push(loc);
      }
      return map;
    }, {});
    /* eslint-enable no-param-reassign */
    return result;
  },

  searchMatcher(location, term) {
    return (`${location.city.toUpperCase()} ${location.county.toUpperCase()} ${location.state.toUpperCase()}`).indexOf(term.toUpperCase());
  },

  _updateLocation(location) {
    let loc = location;
    const dairy = this.get('dairy');

    if (!this.get('application.store').hasRecordForId('location', loc.get('id'))) {
      loc = this.get('application.store').createRecord('location', {
        id: location.id,
        city: location.city,
        county: location.county,
        state: location.state
      });
    } else {
      loc = this.get('application.store').peekRecord('location', loc.get('id'));
    }

    dairy.set('locationId', loc);
    dairy.set('city', loc.get('city'));
    dairy.set('county', loc.get('county'));
    dairy.set('state', loc.get('state'));
  },

  actions: {
    doSearch(searchterm) {
      this.set('searchterm', searchterm);
      debounce(this, this._serachLocations, 350);
    },

    updateDairyLocation(location) {
      return this._updateLocation(location);
    },

    closeSearch() {
      this.set('searchterm', null);
      this.set('searchPromise', []);
    }
  }
});
